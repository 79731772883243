import React, { useState } from 'react';
import './App.css';
import TopMenuBar from './components/TopMenuBar';
import Login from './components/Login';
import { isAuthenticated } from './AppSession'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  return (
    <>
      {isLoggedIn ?
        <TopMenuBar /> :
        <Login />
      }
    </>
  );
}

export default App;
