import axios from 'axios';
import {
  createAction,
  GET_UI_LABELS
} from './action';
import { HEADERS } from '../AppHeaders';

//get UI label list
export const getUiLabelList = (data) => {
  return createAction({
    type: GET_UI_LABELS,
    action: async () => axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getUiLabels`, data, { headers: await HEADERS.SECURED() })
  });
}

//get user preferences details
export const getUserPreference = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getUserPreference`, data, {
    data: {}, headers: await HEADERS.SECURED()
  });
}

