import { SESSION_KEYS } from "./AppHeaders";

export function createSession(userData) {
    localStorage.setItem(SESSION_KEYS.TOKEN, userData.access_token);
    const jwt = parseJwt(userData.access_token);
    const exp = new Date(jwt.exp * 1000);
    
    localStorage.setItem(SESSION_KEYS.EXPIRY, JSON.stringify(exp.getTime()));
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var decoded = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(decoded);
};

export function isAuthenticated() {
    const sessionUserToken = localStorage.getItem(SESSION_KEYS.TOKEN);
    if (sessionUserToken && isExpired()) {
        invalidateSession();
        return false;
    }
    return sessionUserToken ? true : false;
}

function isExpired() {
    const exp = localStorage.getItem(SESSION_KEYS.EXPIRY);
    if (Date.now() <= exp) {
        return false;
    }

    return true;
}

export function invalidateSession() {
    localStorage.removeItem(SESSION_KEYS.TOKEN);
    localStorage.removeItem(SESSION_KEYS.EXPIRY);
}

