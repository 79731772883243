import React, { useState, useRef, useEffect } from 'react';
import { createWorkEffort, createWorkEffortAssoc, createDataResourceAndContent, createDirectoryStructure, performFindList } from '../actions/index';
import { isAuthenticated } from '../AppSession'
import Tree from 'rc-tree';
import { connect } from 'react-redux';

const PROJECT_TEMPLATE = process.env.REACT_APP_PROJECT_TEMPLATE;
const PHASE_TEMPLATE = process.env.REACT_APP_PHASE_TEMPLATE;
const TPRJ_PROPOSED = process.env.REACT_APP_TPRJ_PROPOSED;
const TPRJ2TPHS_ASSOC = process.env.REACT_APP_TPRJ2TPHS_ASSOC;
const TPHS2TPHS_ASSOC = process.env.REACT_APP_TPHS2TPHS_ASSOC;
const DATA_RES_STATUS = process.env.REACT_APP_DATA_RES_STATUS;
const DIR_MIME_TYPE = process.env.REACT_APP_DIR_MIME_TYPE;
const DATA_RES_TYPE = process.env.REACT_APP_DATA_RES_TYPE;
const TEMP_DATA_TEMP = process.env.REACT_APP_TEMP_DATA_TEMP;
const TEMP_DATA_CATEGORY = process.env.REACT_APP_TEMP_DATA_CATEGORY;
const TEMP_CONTENT_TYPE = process.env.REACT_APP_TEMP_CONTENT_TYPE;
const ROOT_DIR = process.env.REACT_APP_TEMP_PROJ_ROOT_DIR;
const HYDROGRAV_PARENT_PURPOSE_TYPE = process.env.REACT_APP_HYDROGRAV_PARENT_PURPOSE_TYPE;

function CreateTemplate(props) {

    const uiLabelMap = props.uiLabelList[0];
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(props.isAuthenticated);
    //store project template structure in state
    const [projectTemplate, setProjectTemplate] = useState();
    //manage phase template modal (show/hide)
    const [showPhaseModal, setShowPhaseModal] = useState(false);
    //store indentification of current selected tree node
    const [selNodeKey, setSelNodeKey] = useState(null);
    //sequence to assign unique key to each tree node
    const [uniqueSeqKey, setUniqueSeqKey] = useState(100);
    //available purpose types 
    const [projectTypeList, setProjectTypeList] = useState([]);
    //store REST API call count (workeffort and association calls) to verify action (create template) completeness
    const [callCount, setCallCount] = useState();
    //store child project type list based on parent project
    const [subProjectTypeList, setSubProjectTypeList] = useState([]);

    //populate purpose type dropdown on component load
    useEffect(() => {
        if (uiLabelMap !== undefined) {
            initSetup();
        }
        populateWFPurposeTypeList();
    }, [uiLabelMap])

    //reference to phase name and project description fields
    const phaseNameRef = useRef();
    const projectDescRef = useRef();
    const purposeTypeRef = useRef();

    const populateWFPurposeTypeList = async () => {
        //project type list
        await performFindList({
            "entityName": "WorkEffortPurposeType",
            "noConditionFind": "Y",
            "inputFields": {
                "parentTypeId": HYDROGRAV_PARENT_PURPOSE_TYPE
            },
            "viewSize": 10000
        }).then(response => {
            setProjectTypeList(response.data.data.list);
        });
    }

    //show phase creation modal
    const openPhaseModal = () => {
        //when a tree is selected to add child phase
        if (selNodeKey != null) {
            setShowPhaseModal(true);
        }
        else {//show warning message otherwise
            alert("Select at least project or one phase");
        }
    }

    const addPhaseNode = (workEffort) => {
        if (isAuthenticated()) {
            let rootNode = projectTemplate;
            //search parent node and return updated tree
            let updateRoot = searchParentAndUpdateTree(rootNode, workEffort);
            //update project template structure with latest state
            setProjectTemplate({ ...updateRoot });
        } else {
            setIsUserLoggedIn(false);
        }
    }

    const searchParentAndUpdateTree = (currentNode, workEffort) => {
        //if required parent node found
        if (currentNode.key === workEffort.workEffortParentId) {
            if (currentNode.children === undefined) {
                currentNode.children = [];
            }
            //push node under parent node and return updated tree
            currentNode.children.push(workEffort);
            return currentNode;
        } else { // if current node has children then search through each node
            if (currentNode.children !== undefined) {
                let updatedPhases = [];
                currentNode.children.forEach(phase => {
                    updatedPhases.push(searchParentAndUpdateTree(phase, workEffort));
                });
                currentNode.children = updatedPhases;
            }
            return currentNode;
        }
    }

    const setProjectTemplateName = () => {
        //if user is not authenticated
        if (isAuthenticated()) {
            addWorkEffort({
                workEffortName: projectDescRef.current.value,
                workEffortTypeId: PROJECT_TEMPLATE,
                currentStatusId: TPRJ_PROPOSED
            });
        } else {
            setIsUserLoggedIn(false);
        }
    }

    const addWorkEffort = (workEffort) => {
        if (isAuthenticated()) {
            //generate or update sequence key
            setUniqueSeqKey(uniqueSeqKey + 1);

            if (projectDescRef.current.value === "" || purposeTypeRef.current.value === "") {
                alert("Enter Project Description and Purpose Type");
            }
            else {
                workEffort.key = uniqueSeqKey;
                workEffort.title = workEffort.workEffortName;
                //project template work effort
                if (workEffort.workEffortParentId === undefined)
                    setProjectTemplate({ ...projectTemplate, ...workEffort });
                //phase template work effort    
                else
                    addPhaseNode(workEffort);
            }
        } else {
            setIsUserLoggedIn(false);
        }
    }

    const onTreeNodeSelect = (selectedKeys, info) => {
        setSelNodeKey(info.node.props.eventKey);
    };

    const addPhaseTemplate = () => {
        if (selNodeKey !== null) {
            if (phaseNameRef.current.value === "") {
                alert("Enter Phase Template Name.");
            }
            else {
                setShowPhaseModal(false);
                //add phase as work effort
                addWorkEffort({ workEffortParentId: selNodeKey, workEffortName: phaseNameRef.current.value, workEffortTypeId: PHASE_TEMPLATE, currentStatusId: TPRJ_PROPOSED });
            }
        }
    }

    const workEffortFail = () => {
        let actCount = callCount;
        actCount.actualCount.wfFailCount += 1;
        setCallCount({ ...actCount });
    }

    const workEffortSucess = () => {
        let actCount = callCount;
        actCount.actualCount.wfSuccessCount += 1;
        setCallCount({ ...actCount });
    }

    const workEffortAssocFail = () => {
        let actCount = callCount;
        actCount.actualCount.assocFailCount += 1;
        setCallCount({ ...actCount });
    }

    const workEffortAssocSucess = () => {
        let actCount = callCount;
        actCount.actualCount.assocSuccessCount += 1;
        setCallCount({ ...actCount });
    }

    //process complete tree using recursion to create project template structure using Rest API 
    const processWorkEffort = async (obj, parentId, assocType, objectInfo) => {

        var workEffortObj = {
            workEffortName: obj.workEffortName,
            workEffortTypeId: obj.workEffortTypeId,
            currentStatusId: obj.currentStatusId
        }

        //directory path for data source and content creation
        objectInfo = objectInfo + "/" + obj.workEffortName;

        if (parentId !== null) {
            workEffortObj.workEffortParentId = parentId;
        } else {
            workEffortObj.workEffortPurposeTypeId = purposeTypeRef.current.value;
        }

        //call the Rest API and create work effort of provided type
        await createWorkEffort(JSON.stringify(workEffortObj)).then(async data => {

            workEffortSucess();
            let genWorkEffortId = data.data.data.workEffortId;

            //associate workeffort project template to phase and phase to sub-phase
            if (parentId !== null && assocType !== null) {
                //association object
                var workEffortAssocObj = {
                    workEffortIdTo: genWorkEffortId,
                    workEffortIdFrom: parentId,
                    workEffortAssocTypeId: assocType
                }

                //call the Rest API and create work effort association of provided type
                createWorkEffortAssoc(JSON.stringify(workEffortAssocObj)).then(sucess => {
                    workEffortAssocSucess();
                }).catch(error => {
                    workEffortAssocFail();
                });
            }

            let dataResourceObj = {
                "workEffortId": genWorkEffortId,
                "dataResourceName": workEffortObj.workEffortName,
                "statusId": DATA_RES_STATUS,
                "mimeTypeId": DIR_MIME_TYPE,
                "objectInfo": objectInfo,
                "dataResourceTypeId": DATA_RES_TYPE,
                "dataTemplateTypeId": TEMP_DATA_TEMP,
                "dataCategoryId": TEMP_DATA_CATEGORY,
                "workEffortContentTypeId": TEMP_CONTENT_TYPE,
                "rootDir": ROOT_DIR
            }

            await createDataResourceAndContent(JSON.stringify(dataResourceObj));

            if (obj.children !== undefined) {
                //default association
                let assocTypeLcl = TPHS2TPHS_ASSOC;

                //association is TPRJ2TPHS_ASSOC between template project and first level phases
                if (parentId === null) {
                    assocTypeLcl = TPRJ2TPHS_ASSOC;
                }
                obj.children.forEach(element => {
                    processWorkEffort(element, genWorkEffortId, assocTypeLcl, objectInfo);
                })
            }
        }).catch(error => {
            workEffortFail();
        });
    }


    //calculate associations betweet workeffort - phase/sub-phase etc
    const calculateWfAssocCount = (obj) => {
        let count = 0;
        if (obj.workEffortParentId !== undefined) {
            count = count + 1;
        }
        if (obj.children !== undefined) {

            obj.children.forEach(item => {
                count = count + calculateWfAssocCount(item);
            });
            return count;
        } else {
            return count;
        }
    }


    const createTemplateProject = async () => {
        //get total associations between tree of work efforts
        let assocCount = calculateWfAssocCount(projectTemplate);
        //initialize total required workefort and association
        let lclCallCount = callCount;
        lclCallCount.requiredCount.wfCount = assocCount + 1;
        lclCallCount.requiredCount.assocCount = assocCount;
        setCallCount({ ...lclCallCount });

        if (isAuthenticated()) {
            let objectInfo = "";
            await processWorkEffort(projectTemplate, null, null, objectInfo).then(res => {
                let dirStructMap = { dirMap: projectTemplate, rootDir: ROOT_DIR };
                createDirectoryStructure(JSON.stringify(dirStructMap)).then(response => {
                    console.log(response);
                });
            });
        } else {
            setIsUserLoggedIn(false);
        }
    }

    const showActionSuccessMsg = () => {
        if (callCount !== undefined) {
            // return true or false, if all the REST API calls are performed successfully - required matches actual
            return (callCount.requiredCount.wfCount !== 0 &&
                callCount.requiredCount.assocCount !== 0 &&
                (callCount.requiredCount.wfCount === callCount.actualCount.wfSuccessCount) &&
                (callCount.requiredCount.assocCount === callCount.actualCount.assocSuccessCount));

        } else {
            return false;
        }
    }

    const initSetup = () => {
        let initCallCount = {
            requiredCount: { wfCount: 0, assocCount: 0 },
            actualCount: {
                assocFailCount: 0,
                assocSuccessCount: 0,
                wfSuccessCount: 0,
                wfFailCount: 0
            }
        }
        setCallCount(initCallCount);
        setProjectTemplate();
        purposeTypeRef.current.value = "";
        projectDescRef.current.value = "";
    }

    const clearData = () => {
        initSetup();
    }

    const getSubProjectTypes = async (event) => {
        const { value } = event.target;
        await performFindList({
            "entityName": "WorkEffortPurposeType",
            "noConditionFind": "Y",
            "inputFields": {
                "parentTypeId": value
            },
            "viewSize": 10000
        }).then(response => {
            if (response.data.data.list !== undefined) {
                setSubProjectTypeList(response.data.data.list);
            } else {
                setSubProjectTypeList([]);
            }
        });
    }

    return (
        <>
            {uiLabelMap !== undefined ?
                <div>
                    <div style={{ backgroundColor: 'gray', height: 1000 }}>
                        {/* Update Project UI */}
                        <div style={{ backgroundColor: '#454545', width: 300, position: 'absolute', top: 80, left: 40 }}>
                            <div >
                                <i className="fas fa-pencil-alt"></i>
                                <h3 style={{ marginLeft: 70 }}>{uiLabelMap.ProjectMgrTemplateName}</h3>
                            </div>
                            <div className="info">
                                <select id="projectType" onChange={getSubProjectTypes} name="parentPurposeTypeId" style={{ width: 300, marginLeft: 0 }}>
                                    <option disabled selected>
                                        Project Type *
                                    </option>
                                    {projectTypeList.map((purposeType, idx) => {
                                        return (
                                            <option value={purposeType.workEffortPurposeTypeId}>
                                                {purposeType.description}
                                            </option>
                                        )
                                    })}
                                </select>
                                <select ref={purposeTypeRef} style={{ width: 300, marginLeft: 0 }}>
                                    <option value="">{uiLabelMap.ProjectMgrWorkEffortPurpose}</option>
                                    {subProjectTypeList.length !== 0 ?
                                        subProjectTypeList.map((purposeType) => {
                                            return (
                                                <option value={purposeType.workEffortPurposeTypeId}>
                                                    {purposeType.description}
                                                </option>
                                            )
                                        })
                                        : null}
                                </select>
                                <input ref={projectDescRef} type="text" name="name" placeholder={uiLabelMap.ProjectMgrTemplateName} />
                            </div>
                            <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
                                <button onClick={setProjectTemplateName}>{uiLabelMap.ProjectMgrUpdate}</button>
                            </div>
                        </div>
                        {/* Button to add Phase/Sub-phase */}
                        {projectTemplate !== undefined ?
                            <div style={{ width: 200, position: 'absolute', left: 460, top: 40 }}>
                                <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
                                    <button onClick={openPhaseModal}>+ {uiLabelMap.ProjectMgrSubPhase}</button>
                                </div>
                            </div> :
                            null}
                        {/* Project Template Tree Structure  */}
                        <div style={{ width: 300, position: 'absolute', left: 740, top: 50 }}>
                            {projectTemplate !== undefined ?
                                <div style={{ display: 'inline-block' }}>
                                    <h3>{uiLabelMap.ProjectMgrTreeStructure}</h3>
                                    <Tree
                                        className="myCls"
                                        showLine
                                        checkable
                                        draggable
                                        selectable={true}
                                        defaultExpandAll
                                        onSelect={onTreeNodeSelect}
                                        treeData={[projectTemplate]}
                                    />

                                </div> : null}
                        </div>

                    </div>
                    {/* Button to Create Project Template in ofbiz using REST API  */}
                    {projectTemplate !== undefined ?
                        <div style={{ width: 160, position: 'absolute', left: 1080, top: 40 }}>
                            <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
                                <button style={{ backgroundColor: 'green', }} onClick={createTemplateProject}>{uiLabelMap.ProjectMgrCreatetemplate}</button>
                            </div>
                        </div> :
                        null}
                    {/* Modal to add Phase under Project  */}
                    {showPhaseModal === true ?
                        <div style={{ backgroundColor: '#454545', width: 300, position: 'absolute', top: 50, left: 440 }}>
                            <div >
                                <i className="fas fa-pencil-alt"></i>
                                <h2>{uiLabelMap.FormFieldTitle_ProjectMgrPhaseName}</h2>
                            </div>
                            <div className="info">
                                <input className="fname" ref={phaseNameRef} name="phaseName" placeholder={uiLabelMap.FormFieldTitle_ProjectMgrPhaseName} />
                            </div>
                            <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
                                <button onClick={addPhaseTemplate}>{uiLabelMap.ProjectMgrCreatePhasetemplate}</button>
                            </div>
                        </div> : null
                    }
                    {showActionSuccessMsg() ?
                        <div style={{ backgroundColor: '#454545', width: 300, position: 'absolute', top: 350, left: 440 }}>
                            <div >
                                <i className="fas fa-pencil-alt"></i>
                                <h4 >Project Template Created Successfully.</h4>
                            </div>
                            <div>
                                <button onClick={clearData}>OK</button>
                            </div>

                        </div> : null
                    }
                </div> : <div class="loader"></div>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        uiLabelList: state.labelsData.uiLabelList
    };
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
