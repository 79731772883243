import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import CreateTemplate from './CreateTemplate';
import CreateProject from './CreateProject';
import ProjectAdministration from './ProjectAdministration';
import userLogo from './../images/user.png'
import { connect } from 'react-redux';
import { getUiLabelList } from './../actions/internationalization';
import { getUserPreference } from '../actions/internationalization';

const LOGIN_USER = process.env.REACT_APP_USER;

class TopMenuBar extends React.Component {

    state = {};

    componentDidMount() {
        this.callBack();
    }

    callBack = async () => {
        let userPrefDataObject = {
            "userPrefGroupTypeId": "GLOBAL_PREFERENCES",
            "userPrefTypeId": "locale",
            "userPrefLoginId": LOGIN_USER
        };
        await getUserPreference(JSON.stringify(userPrefDataObject)).then(async currentUserPreference => {
            await this.props.getUiLabelList({ "screenList": ["ProjectMgrUiLabels"], "localeString": currentUserPreference.data.data.userPrefValue }).then(() => {
                this.setState({
                    uiLabelMap: this.props.uiLabelList[0],
                    locale: currentUserPreference.data.data.userPrefValue
                })
            });
        });
    }

    logout = () => {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('EXPIRY');
    }

    render() {
        return (
            <Router>
                <div>
                    <nav >
                        {this.state.uiLabelMap !== undefined ?
                            <ul style={{ display: 'flex', marginLeft: 13, listStyle: 'none' }} >
                                <li ><Link to={'/createTemplate'} className="list-border"> {this.state.uiLabelMap.ProjectMgrCreatetemplate} </Link></li>
                                <li style={{ marginLeft: 50 }}><Link className="list-border" to={'/createProject'}>{this.state.uiLabelMap.ProjectMgrCreateProject}</Link></li>
                                <li style={{ marginLeft: 50 }}><Link className="list-border" to={'/projectAdministration'}> {this.state.uiLabelMap.ProjectMgrProjectAdmin}</Link></li>
                            </ul> : null}
                    </nav>
                    <div class="dropdown" style={{ float: 'right' }}>
                        <img src={userLogo} class="dropbtn" style={{ width: 30, height: 30 }}></img>
                        <div class="dropdown-content">
                            <a>{this.state.locale !== undefined ? this.state.locale : null}</a>
                            <a onClick={this.logout} href="/">Logout</a>
                        </div>
                    </div>
                    <Switch>
                        <Route exact path='/createTemplate' component={CreateTemplate} />
                        <Route exact path='/createProject' component={CreateProject} />
                        <Route exact path='/projectAdministration' component={ProjectAdministration} />
                        <Redirect to="/createTemplate" />
                    </Switch>
                </div>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        uiLabelList: state.labelsData.uiLabelList
    };
};
const mapDispatchToProps = {
    getUiLabelList
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuBar);