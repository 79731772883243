import baseState from '../store/baseState';
import { GET_UI_LABELS } from '../actions/action';
export default (state = baseState.labelsData, { payload, type, error }) => {
    switch (type) {
        case GET_UI_LABELS.REQUEST:
            return {
                ...state,
            };
        case GET_UI_LABELS.SUCCESS:
            return {
                ...state,
                uiLabelList: payload.data.data.uiLabelList
            };
        default:
            return state;
    }
};