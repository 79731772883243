export const SESSION_KEYS = {
    TOKEN: 'TOKEN',
    EXPIRY: 'EXPIRY'
};

export const HEADERS = {

    PUBLIC: () => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        }
    },

    LOGIN: (basicData) => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${basicData}`
            
        }
    },
    SECURED: async () => {
        return {
            'Accept': 'application/json,text/plain',
            'Authorization': 'Bearer ' + token(),
            'Content-Type': 'application/json',
        };

    }
}

function token() {
    const sessionUsr = localStorage.getItem(SESSION_KEYS.TOKEN);
    if (sessionUsr) {
        const loginUserToken = sessionUsr;
        if (loginUserToken) {
            return loginUserToken;
        }
        return '';
    } else {
        return '';
    }
}