import React, { useState } from 'react';
import { performFindList } from './../actions/index';
import { connect } from 'react-redux';

function AddMember(props) {

    const uiLabelMap = props.uiLabelList[0];
    //store role type list in state
    const [roleTypeList, setRoleTypeList] = useState([]);
    //store party list in state based on selected role type
    const [partyList, setPartyList] = useState([]);
    //store company list 
    const [companyList, setCompanyList] = useState([]);
    //current selected company
    const [selectedMetadata, setSelectedMetadata] = useState({});

    //filtering companies based on user search text
    const onFilterCompany = async (event) => {

        //claring role and party data if company changed by user
        setRoleTypeList([]);
        setPartyList([]);
        setSelectedMetadata({});
        let roleType = document.getElementById('roleType');
        let party = document.getElementById('party');
        roleType.value = "";
        party.value = "";

        //get company list
        const { value } = event.target;
        await performFindList({
            "entityName": "PartyGroup",
            "noConditionFind": "Y",
            "inputFields": {
                "groupName": value,
                "groupName_ic": "Y",
                "groupName_op": "contains"
            },
            "viewSize": 10
        }).then(response => {
            let effectivePartyList = [];
            if (response.data.data.list !== undefined) {
                props.teamPartyList.map((teamParty) => (
                    response.data.data.list.map((party) => {
                        if (teamParty.partyId === party.partyId) {
                            effectivePartyList.push(party);
                        }
                        return teamParty;
                    })
                ));
            }
            setCompanyList(effectivePartyList);
            if (effectivePartyList.length !== 0) {
                effectivePartyList.map(async (partyData) => {
                    if (partyData.groupName === value) {
                        setSelectedMetadata({ ...selectedMetadata, organizationPartyId: partyData.partyId, groupName: partyData.groupName });
                    }
                    return partyData;
                })
            }
        })
    }

    //populate party list dropdown based on selected role
    const handleRoleType = async (event) => {

        //claring party data if role changed by user
        setPartyList([]);
        let party = document.getElementById('party');
        party.value = "";

        const { value } = event.target;
        //getting role type list to compare it with company assigned role in PartyRelationship
        await performFindList({
            "entityName": "RoleType",
            "noConditionFind": "Y",
            "inputFields": {
                "groupName": value,
                "groupName_ic": "Y",
                "groupName_op": "contains",
                "parentTypeId": "PROJECT_TEAM"
            }
        }).then(async response => {
            let effectiveRoleTypeList = [];
            //getting PartyRelationship data to display valid role of a company
            await performFindList({
                "entityName": "PartyRelationship",
                "noConditionFind": "Y",
                "inputFields": {
                    "partyIdFrom": selectedMetadata.organizationPartyId
                },
                "viewSize": 10000
            }).then(partyRoleList => {
                if (response.data.data.list != undefined) {
                    response.data.data.list.map(roleType => {
                        partyRoleList.data.data.list.map(partyRole => {
                            if (roleType.roleTypeId === partyRole.roleTypeIdTo && partyRole.roleTypeIdFrom !== "_NA_") {
                                let idx = effectiveRoleTypeList.findIndex(role => role.roleTypeId === roleType.roleTypeId);
                                if (idx === -1) {
                                    effectiveRoleTypeList.push(roleType);
                                }
                            }
                        })
                    })
                }
            })

            //set effective roles into dropdown
            setRoleTypeList(effectiveRoleTypeList);

            if (roleTypeList !== 0) {
                roleTypeList.map(async (roleData) => {
                    if (roleData.description === value) {
                        setSelectedMetadata({ ...selectedMetadata, roleTypeId: roleData.roleTypeId, roleDesc: roleData.description });
                    }
                })
            }
        });
    }

    //set current selected party object
    const selectParty = async (event) => {
        const { value } = event.target;

        await performFindList({
            "entityName": "PartyRelationship",
            "noConditionFind": "Y",
            "inputFields": {
                "partyIdFrom": selectedMetadata.organizationPartyId,
                "roleTypeIdTo": selectedMetadata.roleTypeId
            },
            "viewSize": 10
        }).then(async directorParties => {
            let effectivePartyList = [];
            if (directorParties.data.data.list !== undefined) {
                Promise.all(directorParties.data.data.list.map((personParty) => (
                    props.personParties.map((party) => {
                        if (personParty.partyIdTo === party.partyId) {
                            let searchText = value.split(",");
                            if (party.lastName.toLowerCase().includes(searchText[0].toLowerCase()) || party.firstName.toLowerCase().includes(searchText[0].toLowerCase())) {
                                effectivePartyList.push(party);
                                if (effectivePartyList.length !== 0) {
                                    effectivePartyList.map((partyData) => {
                                        let combinedName = partyData.lastName + ", " + partyData.firstName;
                                        if (combinedName === value) {
                                            setSelectedMetadata({ ...selectedMetadata, partyId: partyData.partyId, partyLastName: partyData.lastName, partyFirstName: partyData.firstName });
                                        }
                                        return partyData;
                                    })
                                }
                            }
                        }
                        return party;
                    })
                ))).then(() => {
                    setPartyList(effectivePartyList);
                });
            }
        })
    }

    //add current selected party into workeffort party assignment list
    const addPartyRole = () => {
        props.modalData(selectedMetadata);
        let company = document.getElementById('organizationPartyId');
        let roleType = document.getElementById('roleType');
        let party = document.getElementById('party');
        company.value = "";
        roleType.value = "";
        party.value = "";
        setSelectedMetadata({});
        setCompanyList([]);
        setRoleTypeList([]);
        setPartyList([]);
    }

    //if required data is missing
    const disableAddButton = () => {
        alert("Fill required data first...");
    }

    return (
        <div style={{ width: 700, position: 'absolute', top: 80, backgroundColor: 'white', left: 300, height: 500 }}>
            <div style={{ backgroundColor: '#26a9e0', height: 30 }}>
                {uiLabelMap.ProjectMgrAddMember}
                <label style={{ marginLeft: 400 }} onClick={props.onClose}>{uiLabelMap.ProjectMgrCommonClose}</label>
            </div>
            <div style={{ backgroundColor: '#454545', width: 700, height: 500 }}>
                {Object.keys(uiLabelMap).length > 0 ?
                <div>
                    <div>
                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={onFilterCompany} type="text" id="organizationPartyId" name="organizationPartyId" placeholder={uiLabelMap.ProjectMgrCompany+" * "} list="company-list" />
                        <datalist id="company-list">
                            {companyList ?
                                companyList.map((company) => {
                                    return (
                                        <option>
                                            {company.groupName}
                                        </option>
                                    )
                                })
                                : null}
                        </datalist>

                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={handleRoleType} type="text" id="roleType" name="roleTypeId" placeholder={uiLabelMap.ProjectMgrMemberRole + " * "} list="role-list" />
                        <datalist id="role-list">
                            {roleTypeList ?
                                roleTypeList.map((roleType) => {
                                    return (
                                        <option >
                                            {roleType.description}
                                        </option>
                                    )
                                }) : null}
                        </datalist>

                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={selectParty} type="text" id="party" name="partyId" placeholder={uiLabelMap.ProjectMgrParty+" * "} list="party-list" />
                        <datalist style={{ marginLeft: 66 }} id="party-list">
                            {partyList ?
                                partyList.map((party) => {
                                    return (
                                        <option>
                                            {party.lastName + ", " + party.firstName}
                                        </option>
                                    )
                                })
                                : null}
                        </datalist>

                        {selectedMetadata.organizationPartyId != undefined && selectedMetadata.roleTypeId != undefined && selectedMetadata.partyId != undefined ?
                            <button style={{ width: 50, marginLeft: 20, height: 30 }} onClick={() => addPartyRole()}>{uiLabelMap.ProjectMgrCommonAdd}</button> :
                            <button style={{ width: 50, marginLeft: 20, height: 30 }} onClick={() => disableAddButton()}>{uiLabelMap.ProjectMgrCommonAdd}</button>}
                    </div>
                    {props.projectPartyRoles.length !== 0 || props.memberPartyRoles.length !== 0 ?
                        <div className="tablediv">
                            <table style={{ width: 630, marginLeft: 30, marginTop: 30 }}>
                                <tr>
                                    <th>{uiLabelMap.ProjectMgrCompany}</th>
                                    <th>{uiLabelMap.FormFieldTitle_projectMgrGroupName}</th>
                                    <th>{uiLabelMap.ProjectMgrPartyLastName}</th>
                                    <th>{uiLabelMap.ProjectMgrMemberRole}</th>
                                    <th>{uiLabelMap.ProjectMgrStatus}</th>
                                    <th>{uiLabelMap.FormFieldTitle_ProjectMgrfromDate}</th>
                                    <th>{uiLabelMap.ProjectMgrThruDate}</th>
                                </tr>
                                {props.projectPartyRoles !== undefined ? props.projectPartyRoles.map((party) => {
                                    if (party.roleTypeId !== "INTERNAL_ORGANIZATIO" && party.roleTypeId !== "CLIENT_BILLING") {
                                        return (
                                            <tr>
                                                <td>{party.groupName}</td>
                                                <td>{party.partyFirstName}</td>
                                                <td>{party.partyLastName}</td>
                                                <td>{party.roleDesc}</td>
                                                <td>{party.status}</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    }
                                }) : null}
                                {props.memberPartyRoles !== undefined ? props.memberPartyRoles.map((party) => {
                                    return (
                                        <tr>
                                            <td>{party.groupName}</td>
                                            <td>{party.partyFirstName}</td>
                                            <td>{party.partyLastName}</td>
                                            <td>{party.roleDesc}</td>
                                            <td>{party.status}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                }) : null}
                            </table>
                        </div> : null}
                </div>: null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        uiLabelList: state.labelsData.uiLabelList
    };
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMember);