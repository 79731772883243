import React, { useState, useEffect } from 'react';
import { getTemplateProjectPhaseList, createWorkEffort, createWorkEffortAssoc, getProjectPhaseList, createDataResourceAndContent, createPhaseStructure, getValidStatusTransitions, createProjCustomSymbLink, performFindList, assignWorkEffortParyRole, validateTemplateProjectStructure, updateWorkeffort } from './../actions/index';
import { connect } from 'react-redux';
import Tree from 'rc-tree';

//initialize required environment variables
const PROJECT_PHASE = process.env.REACT_APP_WORKEFFORT_TYPE;
const PRJ2PHS_ASSOC = process.env.REACT_APP_PRJ2PHS_ASSOC;
const PHS2PHS_ASSOC = process.env.REACT_APP_PHS2PHS_ASSOC;
const DATA_RES_STATUS = process.env.REACT_APP_DATA_RES_STATUS;
const DIR_MIME_TYPE = process.env.REACT_APP_DIR_MIME_TYPE;
const DATA_RES_TYPE = process.env.REACT_APP_PROJ_DATA_RES_TYPE;
const PROJ_DATA_TEMP = process.env.REACT_APP_PROJ_DATA_TEMP;
const PROJ_DATA_CATEGORY = process.env.REACT_APP_PROJ_DATA_CATEGORY;
const PROJ_CONTENT_TYPE = process.env.REACT_APP_PROJ_CONTENT_TYPE;
const ROOT_DIR = process.env.REACT_APP_PROJ_ROOT_DIR;
const PARTY_ASSIGN_STATUS = process.env.REACT_APP_PARTY_ASSIGN_STATUS;

function ProjectAdministration(props) {

    let uiLabelMap = props.uiLabelList[0];

    //filtered project list
    const [projectList, setProjectList] = useState([]);

    //current selected project data
    const [projectData, setProject] = useState({});

    //current selected project phase list
    const [templatePhaseList, setTemplatePhaseList] = useState({});

    //display project data
    const [showProjectStructure, setShowProjectStructure] = useState(false);

    //current selected project template
    const [templateId, setTemplateId] = useState(null);

    //enable/disable button on click
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    //set current activated phases of current project
    const [checkedKeys, setCheckedKeys] = useState([]);

    //set current tab of selected project
    const [currentTab, setCurrentTab] = useState('status');

    //valid changeable status list for current project
    const [validStatusTransitions, setValidStatusTransitions] = useState([]);

    //current performed action status
    const [actionState, setActionState] = useState(false);

    //parent purpose type
    const [parentPurposeTypeId, setParentPurposeTypeId] = useState(null);

    //project team valid parties
    const [teamPartyList, setTeamPartyList] = useState([]);

    //person parties for comparison
    const [personParties, setPersonParties] = useState([]);

    //parties those are failed to create/link to selected project - set for error purpose
    const [failedPartyAssignments, setFailedPartyAssignments] = useState([]);

    //action message text
    const [actionMessages, setActionMessages] = useState({ msg: "" });

    //store role type list in state
    const [roleTypeList, setRoleTypeList] = useState([]);

    //store party list in state based on selected role type
    const [partyList, setPartyList] = useState([]);

    //store company list 
    const [companyList, setCompanyList] = useState([]);

    //current selected company
    const [selectedMetadata, setSelectedMetadata] = useState({});

    //current assigned parties for current project
    const [assignedWFParties, setAssignedWFParties] = useState([]);

    let unSelectedNodeKey = undefined;
    useEffect(async () => {
        populateMasterData();
        if (templatePhaseList.key !== undefined) {
            await getCheckedPhaseParent(templatePhaseList, unSelectedNodeKey).then(e => {
                setShowProjectStructure(false);
                setTemplatePhaseList(templatePhaseList);
                setShowProjectStructure(true);
            });
        }
    }, [checkedKeys]);

    useEffect(async () => {
        populateMasterData();
    }, []);

    const populateMasterData = async () => {
        await performFindList({
            "entityName": "PartyRole",
            "noConditionFind": "Y",
            "inputFields": {
                "roleTypeId": "PROJECT_TEAM"
            },
            "viewSize": 10000
        }).then(async response => {
            //populate internal organization, client billing and project directors based on team parties
            setTeamPartyList(response.data.data.list);
            //populate person parties
            await performFindList({
                "entityName": "Person",
                "noConditionFind": "Y",
                "inputFields": {
                },
                "viewSize": 10000,
            }).then(partyList => {
                let effectivePartyList = [];
                if (response.data.data.list !== undefined) {
                    response.data.data.list.map(teamParty => {
                        if (partyList.data.data.list !== undefined) {
                            partyList.data.data.list.map(personParty => {
                                if (teamParty.partyId === personParty.partyId) {
                                    effectivePartyList.push(personParty);
                                }
                                return personParty;
                            })
                        }
                        return teamParty;
                    })
                }
                setPersonParties(effectivePartyList);
            });
        })
    }

    //filter project
    const onProjectFilter = async (event) => {
        const { value } = event.target;

        //when filter text has more than 2 chars
        if (value.length > 2) {
            //populate existing project list based on filter string and render tree based on one selected 
            await performFindList({
                "entityName": "WorkEffort",
                "noConditionFind": "Y",
                "inputFields": {
                    "workEffortName": value,
                    "workEffortName_ic": "Y",
                    "workEffortName_op": "contains",
                    "workEffortTypeId": "PROJECT"
                },
                "viewSize": 10
            }).then(async response => {
                setProjectList(response.data.data.list);
                //render project data based on current selected project
                await loadAndRenderSelectedProject(value);
            });
        }
    }

    //load the filtered list of projects and render the tree structure based on selected project from list
    const loadAndRenderSelectedProject = async (value) => {

        // project list returned by API response exist
        if (projectList !== undefined) {
            //iterate and find if a project is selected from auto-complete component, update the state
            projectList.map(async (projectElement, i) => {
                if (projectElement.workEffortName === value || projectElement.workEffortId === value) {
                    //set selected project into state
                    setProject(projectElement);

                    populateValidProjectStatusTransitions(projectElement);

                    // get the selected project's template id
                    let projTemplateId = await getProjectTemplateId(projectElement.workEffortId);
                    //update template into state
                    setTemplateId(projTemplateId);

                    //get array of already created phases of selected project
                    projectElement.phaseId = projectElement.workEffortId;
                    let phaseList = [];

                    await getProjectPhases(projectElement, phaseList).then(async (e) => {
                        let existingPhases = e.phases;
                        //prepare template project element and get template project hierarchy of phases
                        let templateProjElement = { phaseId: projTemplateId, phaseName: "" };
                        await getTemplateProjPhaseList(templateProjElement, existingPhases).then(async (e) => {
                            //update the template project hierarchy into state and initialize tree rendering
                            setTemplatePhaseList(e);
                        });
                    });
                    await getCurrentProjectAssignedParties(projectElement.workEffortId);
                    setShowProjectStructure(true);
                }
            });
        }
    }

    const getCurrentProjectAssignedParties = async (projectId) => {
        let workEffortAssignParties = [];
        let partiesFetchSucess = 0;
        //get assigned parties for current project
        await performFindList({
            "entityName": "WorkEffortPartyAssignView",
            "noConditionFind": "Y",
            "inputFields": {
                "workEffortId": projectId
            },
            "viewSize": 1000
        }).then(async response => {
            if (response != undefined) {
                await performFindList({
                    "entityName": "WorkEffortPartyAssignment",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "workEffortId": projectId
                    },
                    "viewSize": 1000
                }).then(async resData => {
                    await Promise.all(response.data.data.list.map(async assignedParty => {
                        resData.data.data.list.map(async wfCompareAssignParty => {
                            if (assignedParty.partyId === wfCompareAssignParty.partyId && assignedParty.roleTypeId === wfCompareAssignParty.roleTypeId) {
                                await performFindList({
                                    "entityName": "RoleType",
                                    "noConditionFind": "Y",
                                    "inputFields": {
                                        "roleTypeId": assignedParty.roleTypeId
                                    },
                                    "viewSize": 10000
                                }).then(async response1 => {
                                    if (response1 != undefined) {
                                        if (wfCompareAssignParty.organizationPartyId !== undefined) {
                                            await performFindList({
                                                "entityName": "PartyGroup",
                                                "noConditionFind": "Y",
                                                "inputFields": {
                                                    "partyId": wfCompareAssignParty.organizationPartyId
                                                },
                                                "viewSize": 10000
                                            }).then(async response2 => {
                                                await performFindList({
                                                    "entityName": "StatusItem",
                                                    "noConditionFind": "Y",
                                                    "inputFields": {
                                                        "statusId": assignedParty.statusId
                                                    },
                                                    "viewSize": 10000
                                                }).then(async response3 => {
                                                    assignedParty.roleDesc = response1.data.data.list[0].description;
                                                    assignedParty.groupName = response2.data.data.list[0].groupName;
                                                    assignedParty.statusDesc = response3.data.data.list[0].description;
                                                    workEffortAssignParties.push(assignedParty);
                                                    partiesFetchSucess = partiesFetchSucess + 1;
                                                    if (partiesFetchSucess === response.data.data.list.length) {
                                                        setAssignedWFParties(workEffortAssignParties);
                                                    }
                                                })
                                            })
                                        } else {
                                            await performFindList({
                                                "entityName": "StatusItem",
                                                "noConditionFind": "Y",
                                                "inputFields": {
                                                    "statusId": assignedParty.statusId
                                                },
                                                "viewSize": 10000
                                            }).then(async response3 => {
                                                assignedParty.roleDesc = response1.data.data.list[0].description;
                                                assignedParty.statusDesc = response3.data.data.list[0].description;
                                                workEffortAssignParties.push(assignedParty);
                                                partiesFetchSucess = partiesFetchSucess + 1;
                                                if (partiesFetchSucess === response.data.data.list.length) {
                                                    setAssignedWFParties(workEffortAssignParties);
                                                }
                                            })
                                        }
                                    }
                                })

                            }
                        })
                    }));
                })
            }
        })
    }

    const getTemplateProjPhaseList = async (element, projectPhases) => {
        //get immediate sub-phases of a particular phase or work effort
        await getTemplateProjectPhaseList({ "projectId": element.phaseId }).then(async (response) => {
            //populate tree attributes
            element.title = element.phaseName;
            element.key = element.phaseId;
            element.children = response.data.data.phaseList;

            //if project has phases already created
            if (projectPhases !== undefined) {
                //phase matches with one of the already created phases then disable tree node
                projectPhases.map(projectPhase => {
                    if (projectPhase.phaseName === element.title) {
                        element.disableCheckbox = true;
                        element.phaseId = projectPhase.phaseId
                    }
                })
            }

            //loop through all the childen and restrict phase creation (disable tree node) if phase already exist in project phase list 
            if (element.children !== undefined) {
                await Promise.all(element.children.map(async (childElement) => {
                    await getTemplateProjPhaseList(childElement, projectPhases).then(e => {
                        //element.children.push(e);
                    });
                }));
            }
        });
        return element;
    }

    //get current project template ID
    const getProjectTemplateId = async (projectId) => {

        let value = await performFindList({
            //get project template ID based on project ID
            "entityName": "WorkEffortAssoc",
            "noConditionFind": "Y",
            "inputFields": {
                "workEffortIdTo": projectId
            },
            "viewSize": 10000
        }).then(async response => {
            if (response.data.data.list !== undefined) {
                return response.data.data.list[0].workEffortIdFrom;
            } else {
                return null;
            }
        });
        return value;
    }

    //get phases of current project
    const getProjectPhases = async (project, phases) => {
        //call to REST API and get all the immediate phases of a phase or sub-phase
        await getProjectPhaseList({ "projectId": project.phaseId }).then(async (response) => {
            let phaseList = response.data.data.phaseList;
            //if phase has at least one sub-phase
            if (phaseList !== undefined) {
                //get sub-phases of all the children phases and return consolidated list
                await Promise.all(phaseList.map(async (element) => {
                    let phaseLcl = { phaseName: element.phaseName, phaseId: element.phaseId }
                    phases.push(phaseLcl);
                    await getProjectPhases(element, phases);
                }));
            } else {
                // return 
                let phaseLcl = { phaseName: project.phaseName, phaseId: project.phaseId }
                phases.push(phaseLcl);
                project.phases = phases;
                return project;
            }
        });
        project.phases = phases;
        return project;
    }

    //on select particular phase
    const onCheck = async (checkedKeysLcl, info) => {
        if (info.checked === false) {
            unSelectedNodeKey = info.node.key;
        } else {
            unSelectedNodeKey = undefined;
        }
        setCheckedKeys(checkedKeysLcl.checked);
    };

    //get selected phases ancestors and check them all
    const getCheckedPhaseParent = async (project, uncheckedPhase) => {

        const isPhaseUnchecked = uncheckedPhase !== undefined && project.key === uncheckedPhase;

        // if phase has further children
        if (project.children !== undefined) {
            let selChildCount = 0;
            //traverse all the child elements and count selected children
            await Promise.all(project.children.map(async phase => {
                let response = await getCheckedPhaseParent(phase, uncheckedPhase);
                if (response === true) {
                    selChildCount = selChildCount + 1;
                }
                return response;
            }));


            //unselect current tree node (phase) if uncheck event is fired and there is no selected child node
            if (isPhaseUnchecked && selChildCount === 0) {
                const phaseIndex = checkedKeys.findIndex(phaseKey => project.key === phaseKey);
                //if unchecked phase key found in checked keys then remove it
                if (phaseIndex > -1) {
                    let phaseKeyArr = checkedKeys;
                    phaseKeyArr.splice(phaseIndex, 1);
                    setCheckedKeys(phaseKeyArr);
                }
                project.checked = false;
                return false;

                //if phase is already checked and exist in checked keys list, whether checked event is fired for this phase or not    
            } else if (checkedKeys.findIndex(k => project.key === k) > -1) {
                project.checked = true;
                return true;

                //when at least single child phase is selected then select parent    
            } else if (selChildCount > 0) {
                let phaseKeyArr = checkedKeys;
                phaseKeyArr.push(project.key);
                setCheckedKeys(phaseKeyArr);
                project.checked = true;
                return true;
            } else {
                project.checked = false;
                return false;
            }

            // if phase does not has children   
        } else {
            if (isPhaseUnchecked) {
                const phaseIndex = checkedKeys.findIndex(phaseKey => project.key === phaseKey);
                if (phaseIndex > -1) {
                    let phaseKeyArr = checkedKeys;
                    phaseKeyArr.splice(phaseIndex, 1);
                    setCheckedKeys(phaseKeyArr);
                }
                project.checked = false;
                return false;
            } else if (checkedKeys.findIndex(k => project.key === k) > -1) {
                project.checked = true;
                return true;
            } else {
                project.checked = false;
                return false;
            }
        }
    }

    //apply state into OFBiz for selected phases
    const applyProjectStructure = async () => {

        //set button state to loading
        setIsButtonLoading(true);

        let phaseDirObj = {
            activatedPhases: templatePhaseList.children,
            projectId: projectData.workEffortId,
            rootDir: ROOT_DIR
        };


        //iterate through all the selected phases to create them into OFBiz
        let objectInfo = projectData.workEffortName;
        await Promise.all(templatePhaseList.children.map(async (phase) => {
            await updateCreateProjectPhase(phase, projectData.workEffortId, PRJ2PHS_ASSOC, objectInfo);
        })).then(async () => {
            createPhaseStructure(phaseDirObj);

            //create project element template
            let projectElement = { phaseId: projectData.workEffortId };
            let phaseList = [];

            //get activated project phases
            await getProjectPhases(projectElement, phaseList).then(async (elem) => {
                let activatedPhases = elem.phases;
                //prepare project element and get template project hierarchy of phases and compare
                let templateProjElement = { phaseId: templateId, phaseName: "" };
                await getTemplateProjPhaseList(templateProjElement, activatedPhases).then(async (tempPhaseList) => {
                    setShowProjectStructure(false);
                    setActionState(true);
                    setActionMessages({ msg: "Selected phase(s) has been created successfully" });
                    //reset button state
                    setIsButtonLoading(false);
                    //update the project hierarchy into state and initialize tree rendering
                    setTemplatePhaseList(tempPhaseList);
                    //clear selected keys
                    setCheckedKeys([]);
                    setShowProjectStructure(true);
                });
            });
        }).catch(async (e) => {
            //create project element template
            let projectElement = { phaseId: projectData.workEffortId };
            let phaseList = [];

            //get activated project phases
            await getProjectPhases(projectElement, phaseList).then(async (elem) => {
                let activatedPhases = elem.phases;
                //prepare project element and get template project hierarchy of phases and compare
                let templateProjElement = { phaseId: templateId, phaseName: "" };
                await getTemplateProjPhaseList(templateProjElement, activatedPhases).then(async (tempPhaseList) => {
                    setShowProjectStructure(false);
                    //reset button state
                    setIsButtonLoading(false);
                    //update the project hierarchy into state and initialize tree rendering
                    setTemplatePhaseList(tempPhaseList);
                    //clear selected keys
                    setCheckedKeys([]);
                    setShowProjectStructure(true);
                });
            });
        });
    }

    const updateCreateProjectPhase = async (obj, parentId, assocType, objectInfo) => {

        //phase Object
        var workEffortObj = {
            workEffortName: obj.phaseName,
            workEffortTypeId: PROJECT_PHASE,
            currentStatusId: projectData.currentStatusId,
            workEffortParentId: parentId
        }

        objectInfo = objectInfo + "/" + obj.phaseName;

        //if phase is selected in tree structure but not created in OfBiz
        if (obj.checked === true) {
            if (obj.disableCheckbox === undefined) {
                //call the Rest API and create work effort
                await createWorkEffort(JSON.stringify(workEffortObj)).then(async data => {
                    let genWorkEffortId = data.data.data.workEffortId;

                    //associate phase to project and phase to sub-phase
                    if (parentId !== null && assocType !== null) {
                        //association object
                        var workEffortAssocObj = {
                            workEffortIdTo: genWorkEffortId,
                            workEffortIdFrom: parentId,
                            workEffortAssocTypeId: assocType
                        }
                        //call the Rest API and create work effort association of provided type
                        await createWorkEffortAssoc(JSON.stringify(workEffortAssocObj));
                    }

                    let dataResourceObj = {
                        "workEffortId": genWorkEffortId,
                        "dataResourceName": workEffortObj.workEffortName,
                        "statusId": DATA_RES_STATUS,
                        "mimeTypeId": DIR_MIME_TYPE,
                        "objectInfo": "/" + objectInfo,
                        "dataResourceTypeId": DATA_RES_TYPE,
                        "dataTemplateTypeId": PROJ_DATA_TEMP,
                        "dataCategoryId": PROJ_DATA_CATEGORY,
                        "workEffortContentTypeId": PROJ_CONTENT_TYPE,
                        "rootDir": ROOT_DIR
                    }

                    createDataResourceAndContent(JSON.stringify(dataResourceObj));

                    if (obj.children !== undefined) {
                        //Phase to phase association
                        let assocTypeLcl = PHS2PHS_ASSOC;

                        await Promise.all(obj.children.map(element => {
                            updateCreateProjectPhase(element, genWorkEffortId, assocTypeLcl, objectInfo);
                        }));
                    }
                });
            }
            //if phase is already created in OfBiz
            else {
                if (obj.children !== undefined) {
                    await Promise.all(obj.children.map(element => {
                        updateCreateProjectPhase(element, obj.phaseId, PHS2PHS_ASSOC, objectInfo);
                    }));
                }
            }
        }
    }

    //switch project tabs
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    }

    //populate valid project status transitions
    const populateValidProjectStatusTransitions = async (project) => {

        let validStatusTransitionList = [];

        //fetch and populate project status transitions base on parent purpose type and current status of project
        await performFindList({
            "entityName": "WorkEffortPurposeType",
            "noConditionFind": "Y",
            "inputFields": {
                "workEffortPurposeTypeId": project.workEffortPurposeTypeId
            },
            "viewSize": 10000
        }).then(async response => {
            let parntPurposeType = undefined;
            if (response.data.data.list != undefined && response.data.data.list.length > 0) {
                parntPurposeType = response.data.data.list[0];
                //get details of project status using statusId - add current status into valid status transitions
                await performFindList({
                    "entityName": "StatusItem",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "statusId": project.currentStatusId
                    },
                    "viewSize": 10000
                }).then(async sResponse => {
                    let projectStatus = sResponse.data.data.list;

                    if (parntPurposeType !== undefined) {
                        setParentPurposeTypeId(parntPurposeType.parentTypeId);
                        let statusRequestBody = { workEffortPurposeTypeId: parntPurposeType.parentTypeId, currentStatusId: project.currentStatusId };
                        getValidStatusTransitions(JSON.stringify(statusRequestBody)).then(stResponse => {
                            if (stResponse.data.data.validStatusTransitions !== undefined) {
                                validStatusTransitionList = projectStatus.concat(stResponse.data.data.validStatusTransitions);
                                setValidStatusTransitions(validStatusTransitionList);
                            } else {
                                setValidStatusTransitions(projectStatus);
                            }
                        });
                    }
                });
            }
        });
    }

    //update state with project status 
    const handleProjectStatusChange = (event) => {
        const { name, value } = event.target;
        setProject({ ...projectData, [name]: value })

    }

    //update status with REST API
    const updateProjectStatus = async () => {
        setIsButtonLoading(true);

        let symlinkObj = { projectId: projectData.workEffortId, actionType: "unlink" };
        await createProjCustomSymbLink(JSON.stringify(symlinkObj)).then(async res => {
            let updateProjectInfo = {
                "workEffortId": projectData.workEffortId,
                "currentStatusId": projectData.currentStatusId,
                "parentPurposeTypeId": parentPurposeTypeId
            }
            await updateWorkeffort(JSON.stringify(updateProjectInfo)).then(async (response) => {
                setActionState(true);
                setActionMessages({ msg: "Project status has been changed successfully" });
                setIsButtonLoading(false);
                await populateValidProjectStatusTransitions(projectData).then(async res1 => {
                    let symlinkObj = { projectId: projectData.workEffortId, actionType: "link" };
                    await createProjCustomSymbLink(JSON.stringify(symlinkObj));
                    await updateProjectPhaseStatus();
                });
            });
        });
    }

    const updateProjectPhaseStatus = async () => {
        await Promise.all(templatePhaseList.children.map(async (phase) => {
            await updatePhaseStatusRecursively(phase);
        }));
    }

    const updatePhaseStatusRecursively = async (phase) => {

        //phase Object
        var workEffortObj = {
            "workEffortId": phase.phaseId,
            "currentStatusId": projectData.currentStatusId,
            "parentPurposeTypeId": parentPurposeTypeId
        }

        if (phase.disableCheckbox !== undefined) {

            await updateWorkeffort(JSON.stringify(workEffortObj));

            if (phase.children !== undefined) {
                await Promise.all(phase.children.map(async element => {
                    await updatePhaseStatusRecursively(element);
                }));
            }
        }
    }

    //reset project data
    const resetScreenData = () => {
        setActionState(false);
        setActionMessages({ msg: "" });
        setFailedPartyAssignments([]);
    }

    //filter company to assign parties to work effort
    const onFilterCompany = async (event) => {
        const { value } = event.target;
        setRoleTypeList([]);
        setPartyList([]);
        setSelectedMetadata({});
        let roleType = document.getElementById('roleType');
        let party = document.getElementById('party');
        roleType.value = "";
        party.value = "";
        //get company list
        await performFindList({
            "entityName": "PartyGroup",
            "noConditionFind": "Y",
            "inputFields": {
                "groupName": value,
                "groupName_ic": "Y",
                "groupName_op": "contains"
            },
            "viewSize": 10
        }).then(partyGroupList => {
            let effectivePartyList = [];
            if (partyGroupList.data.data.list !== undefined) {
                teamPartyList.map((teamParty) => (
                    partyGroupList.data.data.list.map((party) => {
                        if (teamParty.partyId === party.partyId) {
                            effectivePartyList.push(party);
                        }
                        return teamParty;
                    })
                ));
            }
            setCompanyList(effectivePartyList);
            if (effectivePartyList.length !== 0) {
                effectivePartyList.map(async (partyData) => {
                    if (partyData.groupName === value) {
                        setSelectedMetadata({ ...selectedMetadata, organizationPartyId: partyData.partyId, groupName: partyData.groupName });
                    }
                    return partyData;
                })
            }
        })
    }

    //populate party list dropdown based on selected role
    const handleRoleType = async (event) => {
        const { value } = event.target;
        setPartyList([]);
        let party = document.getElementById('party');
        party.value = "";
        await performFindList({
            "entityName": "RoleType",
            "noConditionFind": "Y",
            "inputFields": {
                "groupName": value,
                "groupName_ic": "Y",
                "groupName_op": "contains",
                "parentTypeId": "PROJECT_TEAM"
            }
        }).then(async response => {
            let effectiveRoleTypeList = [];
            await performFindList({
                "entityName": "PartyRelationship",
                "noConditionFind": "Y",
                "inputFields": {
                    "partyIdFrom": selectedMetadata.organizationPartyId
                },
                "viewSize": 10000
            }).then(partyRoleList => {
                if (response.data.data.list != undefined) {
                    response.data.data.list.map(roleType => {
                        partyRoleList.data.data.list.map(partyRole => {
                            if (roleType.roleTypeId === partyRole.roleTypeIdTo && partyRole.roleTypeIdFrom !== "_NA_") {
                                let idx = effectiveRoleTypeList.findIndex(role => role.roleTypeId === roleType.roleTypeId);
                                if (idx === -1) {
                                    effectiveRoleTypeList.push(roleType);
                                }
                            }
                        })
                    })
                }
            })

            setRoleTypeList(effectiveRoleTypeList);

            if (roleTypeList !== 0) {
                roleTypeList.map(async (roleData) => {
                    if (roleData.description === value) {
                        setSelectedMetadata({ ...selectedMetadata, roleTypeId: roleData.roleTypeId, roleDesc: roleData.description });
                    }
                })
            }
        });
    }

    //set current selected party object
    const selectParty = async (event) => {
        const { value } = event.target;

        //getting party relationships to display effective parties on UI
        await performFindList({
            "entityName": "PartyRelationship",
            "noConditionFind": "Y",
            "inputFields": {
                "partyIdFrom": selectedMetadata.organizationPartyId,
                "roleTypeIdTo": selectedMetadata.roleTypeId
            },
            "viewSize": 10
        }).then(async directorParties => {
            let effectivePartyList = [];
            if (directorParties.data.data.list !== undefined) {
                Promise.all(directorParties.data.data.list.map((personParty) => (
                    personParties.map((party) => {
                        if (personParty.partyIdTo === party.partyId) {
                            let searchText = value.split(",");
                            if (party.lastName.toLowerCase().includes(searchText[0].toLowerCase()) || party.firstName.toLowerCase().includes(searchText[0].toLowerCase())) {
                                effectivePartyList.push(party);
                                if (effectivePartyList.length !== 0) {
                                    effectivePartyList.map((partyData) => {
                                        let combinedName = partyData.lastName + ", " + partyData.firstName;
                                        if (combinedName === value) {
                                            setSelectedMetadata({ ...selectedMetadata, partyId: partyData.partyId, lastName: partyData.lastName, firstName: partyData.firstName });
                                        }
                                        return partyData;
                                    })
                                }
                            }
                        }
                        return party;
                    })
                ))).then(() => {
                    setPartyList(effectivePartyList);
                });
            }
        })
    }

    //add current selected party into workeffort party assignment list
    const addPartyRole = async () => {
        let element = selectedMetadata;
        element.update = true;
        element.statusId = PARTY_ASSIGN_STATUS
        element.workEffortId = projectData.workEffortId;
        await assignWorkEffortParty(element);
        if (failedPartyAssignments.length === 0) {
            loadAndRenderSelectedProject(projectData.workEffortId);
        }
        let company = document.getElementById('organizationPartyId');
        let roleType = document.getElementById('roleType');
        let party = document.getElementById('party');
        company.value = "";
        roleType.value = "";
        party.value = "";
        setSelectedMetadata({});
        setCompanyList([]);
        setRoleTypeList([]);
        setPartyList([]);
    }

    //final link parties to workeffort in OfBiz
    const assignWorkEffortParty = async (element) => {
        return await assignWorkEffortParyRole(element).then(e => {
            return true;
        }).catch(error => {
            failedPartyAssignments.push(error.response.data.errorDescription);
            setActionState(true);
            return false;
        });
    }

    //button disable till required information is not entered properly
    const disableAddButton = () => {
        alert("Fill required data first...");
    }

    return (
        <>
            {uiLabelMap !== undefined ?
                <div style={{ backgroundColor: 'gray', height: 700 }}>
                    <div style={{ backgroundColor: '#454545', padding: 20, height: 500, width: 300, position: 'absolute', top: 80, left: 30 }}>
                        <div >
                            <h3 style={{ textAlign: 'center', marginTop: 1 }}>{uiLabelMap.ProjectMgrProject}</h3>
                        </div>
                        <input className="list-border" autoComplete="off" style={{ width: 290, marginTop: 10 }} type="text" id="wizards" name="projectName" placeholder={uiLabelMap.FormFieldTitle_projectName} list="project-list" onChange={onProjectFilter} />
                        <datalist id="project-list">
                            {projectList ?
                                projectList.map((project) => {
                                    return (
                                        <option value={project.workEffortName}>
                                        </option>
                                    )
                                })
                                : null}
                        </datalist>
                        <li className="list-border" onClick={() => handleTabChange('status')} style={{ listStyle: 'none', color: currentTab === 'status' ? '#999966' : '' }} >{uiLabelMap.ProjectMgrStatus}</li>
                        <li className="list-border" onClick={() => handleTabChange('phases')} style={{ listStyle: 'none', marginTop: 30, color: currentTab === 'phases' ? '#999966' : '' }} >{uiLabelMap.ProjectMgrProjectPhases}</li>
                        <li className="list-border" onClick={() => handleTabChange('members')} style={{ listStyle: 'none', marginTop: 30, color: currentTab === 'members' ? '#999966' : '' }} >{uiLabelMap.ProjectMgrProjectMember}</li>
                    </div>

                    {showProjectStructure === true ?
                        currentTab === 'phases' ?
                            <>
                                <div className="list-border" style={{ width: 300, position: 'absolute', top: 150, left: 500 }}>
                                    <label style={{ width: 200, fontWeight: 'bold', position: 'absolute', top: -20 }}>{uiLabelMap.ProjectMgrProjectStructure}</label>
                                    <label style={{ color: 'black', fontWeight: 'bold' }}>{projectData.workEffortName}</label>
                                    <div style={{ height: 300, overflowY: 'auto' }}>
                                        <Tree
                                            className="myCls"
                                            showLine
                                            checkable
                                            checkStrictly
                                            draggable
                                            checkedKeys={checkedKeys}
                                            selectable={true}
                                            defaultExpandAll
                                            onCheck={onCheck}
                                            treeData={templatePhaseList.children}
                                        />
                                    </div>

                                </div>
                                {isButtonLoading === false ?
                                    <button style={{ position: 'fixed', width: 100, bottom: 20, right: 135 }} onClick={applyProjectStructure}>{uiLabelMap.ProjectMgrSave}</button> :
                                    <button style={{ position: 'fixed', width: 100, bottom: 20, right: 135 }}>{uiLabelMap.ProjectMgrLoading}</button>
                                }
                                <button style={{ position: 'fixed', width: 100, bottom: 20, right: 20 }} >{uiLabelMap.ProjectMgrCancel}</button> :
                            </>
                            : null
                        : null}

                    {/* Project status manage tab */}
                    {showProjectStructure === true ?
                        currentTab === 'status' ?
                            <>
                                <div style={{ width: 500, position: 'absolute', top: 150, left: 500 }}>
                                    <label style={{ width: 200, fontWeight: 'bold', position: 'absolute', top: -20, left: 20 }}>{uiLabelMap.ProjectMgrStatus}</label>
                                    <select className="list-border" onChange={handleProjectStatusChange} name="currentStatusId">
                                        {validStatusTransitions.length !== 0 ?
                                            validStatusTransitions.map((status, idx) => {
                                                if (status.statusId === projectData.currentStatusId) {
                                                    return (
                                                        <option selected value={status.statusId}>
                                                            {status.description}
                                                        </option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={status.statusId}>
                                                            {status.description}
                                                        </option>
                                                    )
                                                }
                                            })
                                            : null
                                        }
                                    </select>
                                </div>
                                {isButtonLoading === false ?
                                    <button style={{ position: 'fixed', width: 100, bottom: 20, right: 135 }} onClick={updateProjectStatus}>{uiLabelMap.ProjectMgrSave}</button> :
                                    <button style={{ position: 'fixed', width: 100, bottom: 20, right: 135 }}>{uiLabelMap.ProjectMgrLoading}</button>
                                }
                                <button style={{ position: 'fixed', width: 100, bottom: 20, right: 20 }} >{uiLabelMap.ProjectMgrCancel}</button> :
                            </>
                            : null
                        : null
                    }

                    {/* Manage project members */}
                    {showProjectStructure === true ?
                        currentTab === 'members' ?
                            <>
                                <div style={{ marginLeft: 430 }}>
                                    <div>
                                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={onFilterCompany} type="text" id="organizationPartyId" name="organizationPartyId" placeholder={uiLabelMap.ProjectMgrCompany + " * "} list="company-list" />
                                        <datalist id="company-list">
                                            {companyList ?
                                                companyList.map((company) => {
                                                    return (
                                                        <option>
                                                            {company.groupName}
                                                        </option>
                                                    )
                                                })
                                                : null}
                                        </datalist>

                                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={handleRoleType} type="text" id="roleType" name="roleTypeId" placeholder={uiLabelMap.ProjectMgrMemberRole + " * "} list="role-list" />
                                        <datalist id="role-list">
                                            {roleTypeList ?
                                                roleTypeList.map((roleType) => {
                                                    return (
                                                        <option >
                                                            {roleType.description}
                                                        </option>
                                                    )
                                                }) : null}
                                        </datalist>

                                        <input autoComplete="off" style={{ marginLeft: 20, marginTop: 20, width: 170 }} onChange={selectParty} type="text" id="party" name="partyId" placeholder={uiLabelMap.ProjectMgrParty + " * "} list="party-list" />
                                        <datalist style={{ marginLeft: 66 }} id="party-list">
                                            {partyList ?
                                                partyList.map((party) => {
                                                    return (
                                                        <option>
                                                            {party.lastName + ", " + party.firstName}
                                                        </option>
                                                    )
                                                })
                                                : null}
                                        </datalist>

                                        {selectedMetadata.organizationPartyId != undefined && selectedMetadata.roleTypeId != undefined && selectedMetadata.partyId != undefined ?
                                            <button style={{ width: 50, marginLeft: 20, height: 30 }} onClick={() => addPartyRole()}>{uiLabelMap.ProjectMgrCommonAdd}</button> :
                                            <button style={{ width: 50, marginLeft: 20, height: 30 }} onClick={() => disableAddButton()}>{uiLabelMap.ProjectMgrCommonAdd}</button>}
                                    </div>
                                    {assignedWFParties.length !== 0 ?
                                        <div className="tablediv">
                                            <table style={{ width: 800, marginTop: 30 }}>
                                                <tr>
                                                    <th>{uiLabelMap.ProjectMgrCompany} </th>
                                                    <th>{uiLabelMap.FormFieldTitle_projectMgrGroupName}</th>
                                                    <th>{uiLabelMap.ProjectMgrPartyLastName}</th>
                                                    <th>{uiLabelMap.ProjectMgrMemberRole}</th>
                                                    <th>{uiLabelMap.ProjectMgrStatus}</th>
                                                    <th>{uiLabelMap.FormFieldTitle_ProjectMgrfromDate}</th>
                                                    <th>{uiLabelMap.ProjectMgrThruDate}</th>
                                                </tr>
                                                {assignedWFParties.map((party) => {
                                                    return (
                                                        <tr>
                                                            <td>{party.groupName}</td>
                                                            <td>{party.lastName !== undefined ? party.lastName : party.groupName}</td>
                                                            <td>{party.firstName}</td>
                                                            <td>{party.roleDesc}</td>
                                                            <td>{party.statusDesc}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div> : null}
                                </div>
                            </>
                            : null
                        : null
                    }

                    {/* Dialog box to display performed action */}
                    {actionState === true ?
                        <div style={{ backgroundColor: '#454545', width: 300, position: 'absolute', top: 200, left: 900 }}>
                            <div >
                                <i className="fas fa-pencil-alt"></i>
                                <h4>{actionMessages.msg}</h4>
                                {failedPartyAssignments.length > 0 ?
                                    <>
                                        <hr />
                                        <h6 style={{ marginLeft: 10, color: 'red' }}>{uiLabelMap.ProjectMgrWarningLogLevel}(s):</h6>
                                        {failedPartyAssignments.map(err => {
                                            return <p style={{ marginLeft: 10 }}> * {err}</p>
                                        })}
                                    </>
                                    :
                                    null
                                }
                            </div>
                            <div>
                                <button onClick={resetScreenData}>{uiLabelMap.ProjectMgrOK}</button>
                            </div>

                        </div> : null}
                </div>
                : null}
        </>
    )
}

const mapStateToProps = state => {
    return {
        uiLabelList: state.labelsData.uiLabelList
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAdministration);


