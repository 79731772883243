import { login } from '../actions/index';
import { useState } from 'react';
import { createSession, isAuthenticated} from '../AppSession';
import TopMenuBar from './TopMenuBar';

const Login = (props) => {
    const [isLoggedIn,setIsLoggedIn] = useState(isAuthenticated());
    const appLogin=() =>{        
        let credentials = { username: process.env.REACT_APP_USER, password: process.env.REACT_APP_PASSWORD }
        login(credentials).then(data => { createSession(data.data.data); }).then(e=>{setIsLoggedIn(true); }).catch(err => { console.log("error   ...." + err);setIsLoggedIn(false);});        
    }

    return (
        <>
            {!isLoggedIn?
                <div className="loginButton">
                    <button onClick={appLogin}>Login</button>
                </div>
                :
                <TopMenuBar isAuthenticated={isLoggedIn}/>
            }
        </>
    );

}

export default Login;