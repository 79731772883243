export const GET_UI_LABELS = createActionType('GET_UI_LABELS');

export function createAction({ action, headers = {}, type }) {
  return async (dispatch) => {
    dispatch({ type: type.REQUEST, headers });
    try {
      dispatch({ type: type.SUCCESS, headers, payload: await action() });
    } catch (error) {
      dispatch({ type: type.FAILURE, headers, error });
    }
  };
}

export function createActionType(id) {
  return {
    FAILURE: `${id}__FAILURE`,
    REQUEST: `${id}__REQUEST`,
    SUCCESS: `${id}__SUCCESS`,
  };
}