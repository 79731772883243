import React, { useState, useEffect } from 'react';
import { assignWorkEffortParyRole, createWorkEffortAssoc, createDataResourceAndContent, createProjectDirectory, getValidStatusTransitions, createProjCustomSymbLink, generateProjectName, createWorkEffortIdentification, createWorkEffortGoodStandard, performFindList, getWorkEffortInfo, createSequenceValueItem, createWorkEffort } from './../actions/index';
import { connect } from 'react-redux';
import AddMember from './AddMember';

const PARTY_ASSIGN_STATUS = process.env.REACT_APP_PARTY_ASSIGN_STATUS;
const PRJ2TPRJ_ASSOC = process.env.REACT_APP_PRJ2TPRJ_ASSOC;
const DATA_RES_STATUS = process.env.REACT_APP_DATA_RES_STATUS;
const DIR_MIME_TYPE = process.env.REACT_APP_DIR_MIME_TYPE;
const DATA_RES_TYPE = process.env.REACT_APP_PROJ_DATA_RES_TYPE;
const PROJ_DATA_TEMP = process.env.REACT_APP_PROJ_DATA_TEMP;
const PROJ_DATA_CATEGORY = process.env.REACT_APP_PROJ_DATA_CATEGORY;
const PROJ_CONTENT_TYPE = process.env.REACT_APP_PROJ_CONTENT_TYPE;
const ROOT_DIR = process.env.REACT_APP_PROJ_ROOT_DIR;
const HYDROGRAV_PARENT_PURPOSE_TYPE = process.env.REACT_APP_HYDROGRAV_PARENT_PURPOSE_TYPE;
const PROJECT_WORK_EFFORT_TYPE = process.env.REACT_APP_PROJECT_WORK_EFFORT_TYPE;
const WF_GOOD_STANDARD_TYPE = process.env.REACT_APP_WORK_EFFORT_GOOD_STANDARD_TYPE;
const WF_GOOD_IDENTIFICATION_TYPE = process.env.REACT_APP_WORK_EFFORT_IDENTIFICATION_TYPE;
const GOOD_IDENTIFICATION_TYPE = process.env.REACT_APP_GOOD_IDENTIFICATION_TYPE;
const TPRJ_ACTIVE = process.env.REACT_APP_TPRJ_ACTIVE;

function CreateProject(props) {

    const uiLabelMap = props.uiLabelList[0];
    const [projectList, setProjectList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [clientBillingPartyList, setClientBillingPartyList] = useState([]);
    const [interCompanyList, setInterCompanyList] = useState([]);
    const [projDirectorList, setProjDirectorList] = useState([]);
    const [projClientDirectorList, setProjClientDirectorList] = useState([]);
    const [projAssignPartyList, setProjAssignPartyList] = useState([]);
    const [projectObj, setProjectObj] = useState({});
    const [projTemplateId, setTemplateId] = useState(null);
    const [actionState, setActionState] = useState(false);
    const [showMemberModal, setShowMemberModal] = useState(false);

    const [projOtherAssignee, setProjOtherAssignee] = useState([]);
    const [teamPartyList, setTeamPartyList] = useState([]);
    const [projectTypeList, setProjectTypeList] = useState([]);
    //populate list of sub-project-purpose-types under selected project-purpose-type
    const [subProjectTypeList, setSubProjectTypeList] = useState([]);

    const [workEffortStatus, setWorkEffortStatus] = useState([]);
    const [organizationObj, setorganizationObj] = useState({});
    const [personParties, setPersonParties] = useState([]);
    const [actionMessages, setActionMessages] = useState({ msg: "", projectName: "" });
    const [productList, setProductList] = useState([]);
    const [resultedProjectName, setResultedProjectName] = useState(null);
    const [blurEffect, setBlurEffect] = useState('');
    const [failedPartyAssignments, setFailedPartyAssignments] = useState([]);
    const [subProjCheckbox, setSubProjCheckbox] = useState(false);
    const [currentSelectedProjectInfo, setCurrentSelectedProjectInfo] = useState({});
    const [currentSelectedProjectId, setCurrentSelectedProjectId] = useState(null);

    useEffect(() => {
        populateMasterData();
    }, []);

    useEffect(() => {
        if (projectObj.workEffortPurposeTypeId !== undefined) {
            onCreateProject(false);
        }
    }, [projectObj]);

    useEffect(() => {
        let providerManager = projAssignPartyList.find(party => party.roleTypeId === 'PROVIDER_MANAGER');
        let customerBilling = projAssignPartyList.find(party => party.roleTypeId === 'CLIENT_BILLING');
        if (providerManager !== undefined || customerBilling !== undefined) {
            onCreateProject(false);
        }

    }, [projAssignPartyList]);

    //populate PROJECT_TEAM parties, project templates, project status list, facility etc
    const populateMasterData = async () => {
        await performFindList({
            "entityName": "PartyRole", "noConditionFind": "Y",
            "inputFields": {
                "roleTypeId": "PROJECT_TEAM"
            },
            "viewSize": 10000
        }).then(async response => {
            //populate internal organization, client billing and project directors based on team parties
            setTeamPartyList(response.data.data.list);
            //populate person parties
            await performFindList({
                "entityName": "Person",
                "noConditionFind": "Y",
                "inputFields": {
                },
                "viewSize": 10000,
            }).then(partyList => {
                let effectivePartyList = [];
                if (response.data.data.list !== undefined) {
                    response.data.data.list.map(teamParty => {
                        if (partyList.data.data.list !== undefined) {
                            partyList.data.data.list.map(personParty => {
                                if (teamParty.partyId === personParty.partyId) {
                                    effectivePartyList.push(personParty);
                                }
                                return personParty;
                            })
                        }
                        return teamParty;
                    })
                }
                setPersonParties(effectivePartyList);
            });
        });

        //project type list
        await performFindList({
            "entityName": "WorkEffortPurposeType",
            "noConditionFind": "Y",
            "inputFields": {
                "parentTypeId": HYDROGRAV_PARENT_PURPOSE_TYPE
            },
            "viewSize": 10000
        }).then(parentPurposeTypeList => {
            setProjectTypeList(parentPurposeTypeList.data.data.list);
        });
    }

    //toggle add member modal
    const addMemberModal = () => {
        if (showMemberModal === true) {
            setBlurEffect('');
        } else {
            setBlurEffect('blur');
        }
        setShowMemberModal(!showMemberModal);
    }

    //create concrete project with party roles and template project
    const onCreateProject = async (isFinal) => {

        let workEffortId = null;

        let today = new Date();
        //set today date in project creation
        let currentSysDate = today.getFullYear().toString().substring(2) + String(today.getMonth() + 1).padStart(2, '0') + String(today.getDate()).padStart(2, '0');

        //get current selected PROVIDER_MANAGER
        let provider = projAssignPartyList.find(party => party.roleTypeId === "PROVIDER_MANAGER");

        //get current selected INTERNAL_ORGANIZATIO
        let internalGroup = projAssignPartyList.find(party => party.roleTypeId === "INTERNAL_ORGANIZATIO");

        //get current selected CLIENT_BILLING
        let clientGroup = projAssignPartyList.find(party => party.roleTypeId === "CLIENT_BILLING");

        //get current selected CLIENT_MANAGER
        let client = projAssignPartyList.find(party => party.roleTypeId === "CLIENT_MANAGER");

        //creating final project into OfBiz
        if (isFinal === true) {
            //if required infomation is missing to create final project like templateId workEffortName etc.
            if (projTemplateId !== null && projectObj.workEffortName !== undefined && projectObj.currentStatusId !== undefined) {

                if (provider !== undefined && client !== undefined && projectObj.facilityId !== undefined) {
                    let projectNameAttributes = { workEffortPurposeTypeId: projectObj.workEffortPurposeTypeId, INTERNAL_DIRECTOR_ID: provider.partyId, INTERNAL_GROUP_ID: internalGroup.partyId, CUSTOMER_GROUP_ID: clientGroup.partyId, CUSTOMER_ID: client.partyId, FAC_MAIN_IDENT: projectObj.facilityId, USER_TEXT: projectObj.workEffortName, createdDate: currentSysDate, PRODUCT_ID: projectObj.productId, final: true };
                    if (subProjCheckbox === true) {
                        projectNameAttributes.projectType = "child";
                        projectNameAttributes.workEffortId = currentSelectedProjectId;
                        projectObj.workEffortParentId = currentSelectedProjectId
                    }
                    //generate project name based on selected metadata
                    await generateProjectName(JSON.stringify(projectNameAttributes)).then(async projectNameDetails => {

                        if (projectNameDetails.status === 200) {
                            //create concrete project
                            projectObj.workEffortTypeId = PROJECT_WORK_EFFORT_TYPE;
                            let workEffortNameUserText = projectObj.workEffortName;
                            projectObj.workEffortName = projectNameDetails.data.data.projectName;

                            await createWorkEffort(projectObj).then(async response => {
                                workEffortId = response.data.data.workEffortId;

                                //if project is not created due to validation error - project status supplied is not applicable
                                if (workEffortId !== undefined) {

                                    //work effort identification Obj
                                    let identificationObj = {
                                        workEffortId: workEffortId,
                                        "nameCompoundDefinitionId": "10001",
                                        "workEffortIdentificationTypeId": WF_GOOD_IDENTIFICATION_TYPE,
                                        "idValue": projectObj.workEffortName,
                                        "partyId": "H0100"
                                    }

                                    //creating final workEffortIdentification into OfBiz
                                    createWorkEffortIdentification(JSON.stringify(identificationObj));

                                    //work Effort Good standard Object
                                    let workEffortGoodStandardObject = {
                                        "workEffortId": workEffortId,
                                        "workEffortGoodStdTypeId": WF_GOOD_STANDARD_TYPE,
                                        "productId": projectObj.productId,
                                        "fromDate": Math.floor(Date.now() / 1000)
                                    }

                                    //creating work Effort Good standard
                                    createWorkEffortGoodStandard(JSON.stringify(workEffortGoodStandardObject));

                                    //assign party roles to project
                                    if (projAssignPartyList.length !== 0) {
                                        let proAssignPartiesData = projAssignPartyList.concat(projOtherAssignee);
                                        await assignMemeberRole(proAssignPartiesData, workEffortId);
                                    }

                                    //associate project with template project
                                    const wfAssocObj = {
                                        workEffortIdTo: workEffortId,
                                        workEffortIdFrom: projTemplateId,
                                        workEffortAssocTypeId: PRJ2TPRJ_ASSOC
                                    }
                                    createWorkEffortAssoc(wfAssocObj).then(response => {
                                        setActionState(true);
                                    });

                                    let dataResourceObj = {
                                        "workEffortId": workEffortId,
                                        "dataResourceName": projectObj.workEffortName,
                                        "statusId": DATA_RES_STATUS,
                                        "mimeTypeId": DIR_MIME_TYPE,
                                        "objectInfo": "/" + projectObj.workEffortName,
                                        "dataResourceTypeId": DATA_RES_TYPE,
                                        "dataTemplateTypeId": PROJ_DATA_TEMP,
                                        "dataCategoryId": PROJ_DATA_CATEGORY,
                                        "workEffortContentTypeId": PROJ_CONTENT_TYPE,
                                        "rootDir": ROOT_DIR
                                    }

                                    if (subProjCheckbox === false) {
                                        createSequenceValueItem(JSON.stringify({ seqName: "subProjectCount_" + workEffortId, workEffortPurposeTypeId: projectObj.workEffortPurposeTypeId }))
                                    }

                                    await createDataResourceAndContent(JSON.stringify(dataResourceObj)).then(async response => {
                                        await createProjectDirectory(JSON.stringify({ projectId: workEffortId, rootDir: ROOT_DIR })).then(res => {
                                            let symlinkObj = { projectId: workEffortId, actionType: "link" };
                                            createProjCustomSymbLink(JSON.stringify(symlinkObj));
                                        });
                                    });

                                    //set toast message if project created into OfBiz successfully
                                    setActionMessages({ ...actionMessages, msg: "Project Created Successfully: ", projectName: projectNameDetails.data.data.projectName });

                                    //clear app state data if project created successfully
                                    clearAppState();

                                } else {
                                    console.log('Project status is not applicable for selected WorkEffortPurposeType.');
                                }
                            }).catch(error => {
                                projectObj.workEffortName = workEffortNameUserText;
                                let errorDescription = error.response.data.errorDescription;
                                let errorIndexAt = errorDescription.indexOf("value ");
                                let errorSliced = errorDescription.slice(errorIndexAt);
                                let displayError = errorSliced.substring(0, 46);
                                setActionMessages({ ...actionMessages, msg: displayError });
                                setActionState(true);
                            });
                            //if project name is not generated successfully
                        } else if (projectNameDetails.status === 422) {
                            setActionMessages({ ...actionMessages, msg: projectNameDetails.data.errorDescription });
                            setActionState(true);
                        } else {
                            setActionMessages({ ...actionMessages, msg: "Something went wrong.. Try Again" });
                            setActionState(true);
                        }
                    })
                } else {
                    alert("Please provide required data...");
                }
            }
            else {
                alert('Please enter Project Name, Project Status and Project Template.');
            }
            //creating project name not finally project into OfBiz
        } else {

            let projectNameAttributes = { final: false, createdDate: currentSysDate };
            if (subProjCheckbox === true) {
                projectNameAttributes.projectType = "child";
                projectNameAttributes.workEffortId = currentSelectedProjectId
            }

            if (projectObj.workEffortPurposeTypeId !== undefined) {
                projectNameAttributes.workEffortPurposeTypeId = projectObj.workEffortPurposeTypeId;
            }
            if (provider !== undefined) {
                projectNameAttributes.INTERNAL_DIRECTOR_ID = provider.partyId;
            }
            if (internalGroup !== undefined) {
                projectNameAttributes.INTERNAL_GROUP_ID = internalGroup.partyId;
            }
            if (clientGroup !== undefined) {
                projectNameAttributes.CUSTOMER_GROUP_ID = clientGroup.partyId;
            }
            if (client !== undefined) {
                projectNameAttributes.CUSTOMER_ID = client.partyId;
            }
            if (projectObj.facilityId !== undefined) {
                projectNameAttributes.FAC_MAIN_IDENT = projectObj.facilityId;
            }
            if (projectObj.workEffortName !== undefined) {
                projectNameAttributes.USER_TEXT = projectObj.workEffortName;
            }
            if (projectObj.productId !== undefined) {
                projectNameAttributes.PRODUCT_ID = projectObj.productId;
            }
            //generate project name based on selected metadata
            await generateProjectName(JSON.stringify(projectNameAttributes)).then(async projectNameDetails => {

                if (projectNameDetails.status === 200) {
                    setResultedProjectName(projectNameDetails.data.data.projectName);
                } else if (projectNameDetails.status === 422) {
                    setActionMessages({ ...actionMessages, msg: projectNameDetails.data.errorDescription });
                    setActionState(true);
                } else {
                    setActionMessages({ ...actionMessages, msg: "Something went wrong.. Try Again" });
                    setActionState(true);
                }
            })
        }
    }

    //assign party to project into OfBiz
    const assignPartyRole = async (element) => {
        return await assignWorkEffortParyRole(element).then(e => {
            return true;
        }).catch(error => {
            failedPartyAssignments.push(error.response.data.errorDescription);
            return false;
        });
    }

    //assign project member recursively
    const assignMemeberRole = async (assignments, workEffortId) => {
        if (assignments.length === 0) {
            return true;
        } else {
            let assignment = assignments[0];
            assignment.workEffortId = workEffortId;
            await assignPartyRole(assignment);
            assignments.splice(0, 1);
            return assignMemeberRole(assignments, workEffortId);
        }
    }

    //updating project info at UI 
    const updateProjectConfig = async (event) => {
        const { name, value } = event.target;
        setProjectObj({ ...projectObj, [name]: value });
        //project template list
        if (name === "workEffortPurposeTypeId") {
            await performFindList({
                "entityName": "WorkEffort",
                "noConditionFind": "Y",
                "inputFields": {
                    "workEffortTypeId": "PROJECT_TEMPLATE",
                    "workEffortPurposeTypeId": value,
                    "currentStatusId": TPRJ_ACTIVE
                },
                "viewSize": 10
            }).then(response => {
                if (response.data.data.list !== undefined) {
                    setTemplateId(response.data.data.list[0].workEffortId)
                }
            });
        }
    }

    //update member party roles
    const addMemberModalData = (partyData) => {
        let party = { partyId: partyData.partyId, roleTypeId: partyData.roleTypeId, statusId: PARTY_ASSIGN_STATUS, partyLastName: partyData.partyLastName, partyFirstName: partyData.partyFirstName, roleDesc: partyData.roleDesc, groupName: partyData.groupName, organizationPartyId: partyData.organizationPartyId }
        setProjOtherAssignee([...projOtherAssignee, party]);
    }

    //clean info/error messages on action completed 
    const cleanData = async () => {
        setActionState(false);
        setFailedPartyAssignments([]);
        setActionMessages({ ...actionMessages, msg: "", projectName: "" });
    }

    //remove already assigned party-role
    const removeAssignedParty = (partyData) => {
        const partyIndex = projOtherAssignee.findIndex(prod => prod.partyId === partyData.partyId);
        if (partyIndex > -1) {
            projOtherAssignee.splice(partyIndex, 1);
        }
        setProjOtherAssignee([...projOtherAssignee]);
    }

    //update autocompleter option list based on filter text
    const onFilterTextChange = async (event) => {
        const { name, value } = event.target;
        //when filter text has more than 2 chars
        if (value.length > 0) {

            //if user selecting internal organization of project
            if (name === "INTERNAL_ORGANIZATIO") {
                await performFindList({
                    "entityName": "PartyGroup",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "groupName": value,
                        "groupName_ic": "Y",
                        "groupName_op": "contains"
                    },
                    "viewSize": 10
                }).then(response => {
                    let effectivePartyList = [];
                    if (response.data.data.list !== undefined) {
                        teamPartyList.map((teamParty) => (
                            response.data.data.list.map((party) => {
                                if (teamParty.partyId === party.partyId) {
                                    effectivePartyList.push(party);
                                }
                                return teamParty;
                            })
                        ));
                    }
                    setInterCompanyList(effectivePartyList);
                    if (effectivePartyList.length !== 0) {
                        effectivePartyList.map(async (partyData) => {
                            let party = { partyId: partyData.partyId, roleTypeId: name, statusId: PARTY_ASSIGN_STATUS, groupName: partyData.groupName };
                            if (partyData.groupName === value) {
                                setProjAssignPartyList([...projAssignPartyList, party]);
                                setorganizationObj({ ...organizationObj, internalOrgId: partyData.partyId, internalGroupName: partyData.groupName })
                            }
                            else {
                                const internalCompanyIndex = projAssignPartyList.findIndex(prod => prod.roleTypeId === name);
                                if (internalCompanyIndex > -1) {
                                    projAssignPartyList.splice(internalCompanyIndex, 1);
                                }
                                setProjAssignPartyList([...projAssignPartyList]);
                            }
                            return partyData;
                        })
                    }
                })
                //if user selecting provider manager of project
            } else if (name === "PROVIDER_MANAGER" && organizationObj.internalOrgId !== undefined) {
                await performFindList({
                    "entityName": "PartyRelationship",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "partyIdFrom": organizationObj.internalOrgId,
                        "roleTypeIdTo": name
                    },
                    "viewSize": 10
                }).then(async directorParties => {
                    await performFindList({
                        "entityName": "PartyRelationship",
                        "noConditionFind": "Y",
                        "inputFields": {
                            "partyIdFrom": organizationObj.internalOrgId,
                            "roleTypeIdTo": "OWNER"
                        },
                        "viewSize": 10
                    }).then(ownerParties => {
                        let relatedParties = directorParties.data.data.list;
                        let effectivePartyList = [];
                        if (ownerParties.data.data.list !== undefined && relatedParties !== undefined) {
                            relatedParties = relatedParties.concat(ownerParties.data.data.list);
                        } else if (ownerParties.data.data.list !== undefined) {
                            relatedParties = ownerParties.data.data.list;
                        }
                        if (relatedParties !== undefined) {
                            relatedParties.map((personParty) => (
                                personParties.map((party) => {
                                    if (personParty.partyIdTo === party.partyId) {
                                        let searchText = value.split(",");
                                        if (party.lastName.toLowerCase().includes(searchText[0].toLowerCase()) || party.firstName.toLowerCase().includes(searchText[0].toLowerCase())) {
                                            effectivePartyList.push(party);
                                            setProjDirectorList(effectivePartyList);
                                            if (effectivePartyList.length !== 0) {
                                                effectivePartyList.map((partyData) => {
                                                    let party = { partyId: partyData.partyId, roleTypeId: name, statusId: PARTY_ASSIGN_STATUS, partyFirstName: partyData.firstName, partyLastName: partyData.lastName, groupName: organizationObj.internalGroupName, roleDesc: "Provider Manager", organizationPartyId: organizationObj.internalOrgId };
                                                    let combinedName = partyData.lastName + ", " + partyData.firstName;
                                                    if (combinedName === value) {
                                                        setProjAssignPartyList([...projAssignPartyList, party]);
                                                    }
                                                    else {
                                                        const providerManagerIndex = projAssignPartyList.findIndex(prod => prod.roleTypeId === name);
                                                        if (providerManagerIndex > -1) {
                                                            projAssignPartyList.splice(providerManagerIndex, 1);
                                                        }
                                                        setProjAssignPartyList([...projAssignPartyList]);
                                                    }
                                                    return partyData;
                                                })
                                            }
                                        } else {

                                        }
                                    }
                                    return party;
                                })
                            ));
                        }
                    })
                })
                //if user selecting client company of project
            } else if (name === "CLIENT_BILLING") {
                await performFindList({
                    "entityName": "PartyGroup",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "groupName": value,
                        "groupName_ic": "Y",
                        "groupName_op": "contains"
                    },
                    "viewSize": 10
                }).then(response => {
                    let effectivePartyList = [];
                    if (response.data.data.list !== undefined) {
                        teamPartyList.map((teamParty) => (
                            response.data.data.list.map((party) => {
                                if (teamParty.partyId === party.partyId) {
                                    effectivePartyList.push(party);
                                }
                                return teamParty;
                            })
                        ));
                    }
                    setClientBillingPartyList(effectivePartyList);
                    if (effectivePartyList.length !== 0) {
                        effectivePartyList.map(async (partyData) => {
                            let party = { partyId: partyData.partyId, roleTypeId: name, statusId: PARTY_ASSIGN_STATUS };
                            if (partyData.groupName === value) {
                                setProjAssignPartyList([...projAssignPartyList, party]);
                                setorganizationObj({ ...organizationObj, billingOrgId: partyData.partyId, clientGroupName: partyData.groupName })
                            }
                            else {
                                const internalCompanyIndex = projAssignPartyList.findIndex(prod => prod.roleTypeId === name);
                                if (internalCompanyIndex > -1) {
                                    projAssignPartyList.splice(internalCompanyIndex, 1);
                                }
                                setProjAssignPartyList([...projAssignPartyList]);
                            }
                            return partyData;
                        })
                    }
                })
                //if user selecting client manager of project
            } else if (name === "CLIENT_MANAGER" && organizationObj.billingOrgId !== undefined) {
                await performFindList({
                    "entityName": "PartyRelationship",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "partyIdFrom": organizationObj.billingOrgId,
                        "roleTypeIdTo": name
                    },
                    "viewSize": 10
                }).then(async clientDirParties => {
                    await performFindList({
                        "entityName": "PartyRelationship",
                        "noConditionFind": "Y",
                        "inputFields": {
                            "partyIdFrom": organizationObj.billingOrgId,
                            "roleTypeIdTo": "OWNER"
                        },
                        "viewSize": 10
                    }).then(ownerParties => {
                        let relatedParties = clientDirParties.data.data.list;
                        let effectivePartyList = [];
                        if (ownerParties.data.data.list !== undefined && relatedParties !== undefined) {
                            relatedParties = relatedParties.concat(ownerParties.data.data.list);
                        } else if (ownerParties.data.data.list !== undefined) {
                            relatedParties = ownerParties.data.data.list;
                        }
                        if (relatedParties !== undefined) {
                            relatedParties.map((personParty) => (
                                personParties.map((party) => {
                                    if (personParty.partyIdTo === party.partyId) {
                                        let searchText = value.split(",");
                                        if (party.lastName.toLowerCase().includes(searchText[0].toLowerCase()) || party.firstName.toLowerCase().includes(searchText[0].toLowerCase())) {
                                            effectivePartyList.push(party);
                                            setProjClientDirectorList(effectivePartyList);
                                            if (effectivePartyList.length !== 0) {
                                                effectivePartyList.map((partyData) => {
                                                    let party = { partyId: partyData.partyId, roleTypeId: name, statusId: PARTY_ASSIGN_STATUS, partyLastName: partyData.lastName, partyFirstName: partyData.firstName, groupName: organizationObj.clientGroupName, roleDesc: "Client Manager", organizationPartyId: organizationObj.billingOrgId };
                                                    let combinedName = partyData.lastName + ", " + partyData.firstName;
                                                    if (combinedName === value) {
                                                        setProjAssignPartyList([...projAssignPartyList, party]);
                                                    }
                                                    else {
                                                        const providerManagerIndex = projAssignPartyList.findIndex(prod => prod.roleTypeId === name);
                                                        if (providerManagerIndex > -1) {
                                                            projAssignPartyList.splice(providerManagerIndex, 1);
                                                        }
                                                        setProjAssignPartyList([...projAssignPartyList]);
                                                    }
                                                    return partyData;
                                                })
                                            }
                                        } else {

                                        }
                                    }
                                    return party;
                                })
                            ));
                        }
                    })
                })
            }
        } else {
            //deselecting input data if not any company selected by user
            if (name === "PROVIDER_MANAGER") {
                setProjDirectorList([]);
            } else if (name === "CLIENT_MANAGER") {
                setProjClientDirectorList([]);
            } else if (name === "CLIENT_BILLING") {
                setClientBillingPartyList([]);
            } else if (name === "INTERNAL_ORGANIZATIO") {
                setInterCompanyList([]);
            }
        }
    }

    //fetch sub-project-purpose-types
    const getSubProjectTypes = async (event) => {
        setWorkEffortStatus([]);
        const { name, value } = event.target;
        setProjectObj({ ...projectObj, [name]: value });
        await performFindList({
            "entityName": "WorkEffortPurposeType",
            "noConditionFind": "Y",
            "inputFields": {
                "parentTypeId": value
            },
            "viewSize": 10000
        }).then(childProjectTypeList => {
            if (childProjectTypeList.data.data.list !== undefined) {
                setSubProjectTypeList(childProjectTypeList.data.data.list);
            } else {
                setSubProjectTypeList([]);
            }
        });

        await getValidProjectStatusList(value)
    }

    const getValidProjectStatusList = async (workEffortPurposeTypeId) => {
        //fetch valid status transitions based on selected work effort purpose type
        getValidStatusTransitions(JSON.stringify({ workEffortPurposeTypeId: workEffortPurposeTypeId })).then(stResponse => {
            if (stResponse.data.data.validStatusTransitions !== undefined) {
                setWorkEffortStatus(stResponse.data.data.validStatusTransitions);
            }
            else {
                setWorkEffortStatus([]);
            }
        });
    }

    //filtering project facilities 
    const onFilterFacility = async (event) => {
        const { value } = event.target;
        //facility list
        await performFindList({
            "entityName": "Facility",
            "noConditionFind": "Y",
            "inputFields": {
                "facilityName": value,
                "facilityName_ic": "Y",
                "facilityName_op": "contains"
            },
            "viewSize": 10
        }).then(facilityListData => {
            setFacilityList(facilityListData.data.data.list);
            if (facilityListData.data.data.list !== undefined) {
                facilityListData.data.data.list.map(facility => {
                    if (value === facility.facilityName) {
                        setProjectObj({ ...projectObj, facilityId: facility.facilityId });
                    }
                    return facility;
                })
            }
        });
    }

    //filtering product based on user search text
    const onFilterProduct = async (event) => {
        const { value } = event.target;
        //facility list
        await performFindList({
            "entityName": "Product",
            "noConditionFind": "Y",
            "inputFields": {
                "productName": value,
                "productName_ic": "Y",
                "productName_op": "contains"
            },
            "viewSize": 10
        }).then(async productListData => {

            let effectiveProductList = [];

            await performFindList({
                "entityName": "GoodIdentification",
                "noConditionFind": "Y",
                "inputFields": {
                    "goodIdentificationTypeId": GOOD_IDENTIFICATION_TYPE
                },
                "viewSize": 10000
            }).then(productIdentificationList => {
                if (productIdentificationList.data.data.list !== undefined || productIdentificationList.data.data.list.length !== 0) {
                    if (productListData.data.data.list !== undefined || productListData.data.data.list.length !== 0) {
                        productListData.data.data.list.map(prod => {
                            productIdentificationList.data.data.list.map(prodIdentification => {
                                if (prod.productId === prodIdentification.productId) {
                                    effectiveProductList.push(prod);
                                }
                            })
                        })
                    }
                }
            })

            setProductList(effectiveProductList);
            if (productListData.data.data.list !== undefined) {
                productListData.data.data.list.map(product => {
                    if (value === product.productName) {
                        setProjectObj({ ...projectObj, productId: product.productId });
                    }
                    return product;
                })
            }
        });
    }

    //searching parent project based on user search text 
    const onFilterParentProject = async (event) => {
        const { value } = event.target;
        //parent project list
        await performFindList({
            "entityName": "WorkEffort",
            "noConditionFind": "Y",
            "inputFields": {
                "workEffortName": value,
                "workEffortName_ic": "Y",
                "workEffortName_op": "contains",
                "workEffortTypeId": "PROJECT"
            },
            "viewSize": 10
        }).then(workEffortList => {
            if (workEffortList.data.data.list !== undefined) {
                setProjectList(workEffortList.data.data.list);
            }
            if (workEffortList.data.data.list !== undefined) {
                workEffortList.data.data.list.map(async project => {
                    if (value === project.workEffortName) {
                        setCurrentSelectedProjectId(project.workEffortId);
                        setProjectObj({ ...projectObj, workEffortParentId: project.workEffortId });
                        if (subProjCheckbox === true) {
                            await getParentProjectInfo(project.workEffortId);
                        }
                    }
                    return project;
                })
            }
        });
    }

    const getParentProjectInfo = async (workEffortId) => {
        getWorkEffortInfo({ workEffortId: workEffortId }).then(async workEffortDetails => {
            await performFindList({
                "entityName": "WorkEffortPurposeType",
                "noConditionFind": "Y",
                "inputFields": {
                    "workEffortPurposeTypeId": workEffortDetails.data.data.workEffort.workEffortPurposeTypeId
                },
                "viewSize": 10
            }).then(async workEffortPurTypeList => {
                getValidProjectStatusList(workEffortPurTypeList.data.data.list[0].parentTypeId);
                await performFindList({
                    "entityName": "WorkEffortPurposeType",
                    "noConditionFind": "Y",
                    "inputFields": {
                        "workEffortPurposeTypeId": workEffortPurTypeList.data.data.list[0].parentTypeId
                    },
                    "viewSize": 10
                }).then(async childWFPurTypeList => {
                    await performFindList({
                        "entityName": "WorkEffortAssoc",
                        "noConditionFind": "Y",
                        "inputFields": {
                            "workEffortIdTo": workEffortId
                        },
                        "viewSize": 10
                    }).then(async workEffortAssocDetails => {
                        await performFindList({
                            "entityName": "WorkEffort",
                            "noConditionFind": "Y",
                            "inputFields": {
                                "workEffortId": workEffortAssocDetails.data.data.list[0].workEffortIdFrom
                            },
                            "viewSize": 10
                        }).then(async wfTemplateDetails => {
                            await performFindList({
                                "entityName": "Facility",
                                "noConditionFind": "Y",
                                "inputFields": {
                                    "facilityId": workEffortDetails.data.data.workEffort.facilityId
                                },
                                "viewSize": 10
                            }).then(async facilityDetails => {
                                setProjectObj({ ...projectObj, workEffortPurposeTypeId: workEffortDetails.data.data.workEffort.workEffortPurposeTypeId, currentStatusId: workEffortDetails.data.data.currentStatusItem.statusId, parentPurposeTypeId: workEffortPurTypeList.data.data.list[0].parentTypeId, facilityId: workEffortDetails.data.data.workEffort.facilityId })
                                setCurrentSelectedProjectInfo({ ...currentSelectedProjectInfo, workEffortId: workEffortId, parentPurposeType: childWFPurTypeList.data.data.list[0].description, purposeType: workEffortPurTypeList.data.data.list[0].description, templateName: wfTemplateDetails.data.data.list[0].workEffortName, currentStatus: workEffortDetails.data.data.currentStatusItem.description })
                                setTemplateId(workEffortAssocDetails.data.data.list[0].workEffortIdFrom);
                                let facilityId = document.getElementById('facilityId');
                                facilityId.value = facilityDetails.data.data.list[0].facilityName;
                            })
                        })
                    })
                })
            })
        })
    }

    const clearAppState = () => {
        setProjectObj({});
        setorganizationObj({});
        setProjAssignPartyList([]);
        setProjOtherAssignee([]);
        setResultedProjectName(null);
        setCurrentSelectedProjectInfo({});
        setSubProjCheckbox(false);
        setCurrentSelectedProjectId(null);
        let projecttype = document.getElementById('projectType');
        let childProjectType = document.getElementById('childProjectType');
        let projectStatus = document.getElementById('projectStatus');
        let workEffortParentId = document.getElementById('workEffortParentId');
        let CLIENT_MANAGER = document.getElementById('CLIENT_MANAGER');
        let CLIENT_BILLING = document.getElementById('CLIENT_BILLING');
        let PROVIDER_MANAGER = document.getElementById('PROVIDER_MANAGER');
        let facilityId = document.getElementById('facilityId');
        let productId = document.getElementById('productId');
        let INTERNAL_ORGANIZATIO = document.getElementById('INTERNAL_ORGANIZATIO');
        let resultProj = document.getElementById('resultProj');
        projecttype.value = "Purpose Type *";
        childProjectType.value = "Child Purpose Type *";
        projectStatus.value = "Project Status *";
        workEffortParentId.value = "";
        CLIENT_MANAGER.value = "";
        CLIENT_BILLING.value = "";
        PROVIDER_MANAGER.value = "";
        facilityId.value = "";
        INTERNAL_ORGANIZATIO.value = "";
        productId.value = "";
        resultProj.value = "";
    }

    const handleSubProjectCheckbox = () => {
        setSubProjCheckbox(!subProjCheckbox);
    }

    return (
        <>
            {uiLabelMap !== undefined ?
                <>
                    <div className={blurEffect} style={{ backgroundColor: 'gray', height: 1000 }}>
                        <div style={{ backgroundColor: '#454545', width: 800, position: 'absolute', top: 80, left: 40 }}>
                            <div className='row'>
                                <i className="fas fa-pencil-alt"></i>
                                <h3 style={{ marginLeft: 300 }}>{uiLabelMap.ProjectMgrNewProject}</h3>
                                <button style={{ cursor: 'pointer', width: 40, height: 30, fontSize: 10, marginLeft: 20, position: 'absolute', top: -8, right: 40 }} onClick={clearAppState}>{uiLabelMap.ProjectMgrClearBtn}</button>
                                <div style={{ position: 'absolute', top: 20, left: 550 }}>
                                    <label style={{ marginRight: 10 }}>{uiLabelMap.ProjectMgrNewSubProject}</label>
                                    <label class="switch">
                                        <input name='subProjectCheckbox' type="checkbox" checked={subProjCheckbox} onClick={handleSubProjectCheckbox} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <select disabled={subProjCheckbox} id="projectType" onChange={getSubProjectTypes} name="parentPurposeTypeId" style={{ marginLeft: 66 }}>
                                    <option disabled selected>
                                        {uiLabelMap.ProjectMgrWorkEffortPurposeTypeId} *
                                    </option>
                                    {projectTypeList !== null && subProjCheckbox === false ?
                                        projectTypeList.map((purposeType, idx) => {
                                            return (
                                                <option value={purposeType.workEffortPurposeTypeId}>
                                                    {purposeType.description}
                                                </option>
                                            )
                                        })
                                        :
                                        currentSelectedProjectInfo.parentPurposeType !== undefined ?
                                            <option selected>
                                                {currentSelectedProjectInfo.parentPurposeType}
                                            </option> : null}
                                </select>
                                <select id="childProjectType" disabled={subProjCheckbox} onChange={updateProjectConfig} name="workEffortPurposeTypeId" >
                                    <option disabled selected>
                                        {uiLabelMap.ProjectMgrWorkEffortChildPurposeTypeId} *
                                    </option>
                                    {subProjectTypeList.length !== 0 ?
                                        subProjectTypeList.map((purposeType, idx) => {
                                            return (
                                                <option value={purposeType.workEffortPurposeTypeId}>
                                                    {purposeType.description}
                                                </option>
                                            )
                                        })
                                        :
                                        currentSelectedProjectInfo.purposeType !== undefined ?
                                            <option selected>
                                                {currentSelectedProjectInfo.purposeType}
                                            </option> : null}
                                </select>
                                <br></br>
                                <select id="projectStatus" style={{ marginLeft: 66 }} onChange={updateProjectConfig} name="currentStatusId">
                                    {projectObj.currentStatusId == null ? <option disabled selected>
                                        {uiLabelMap.ProjectMgrStatus} *
                                    </option> : <option disabled>
                                        {uiLabelMap.ProjectMgrStatus} *
                                    </option>}
                                    {workEffortStatus.length !== 0 ?
                                        workEffortStatus.map((status, idx) => {
                                            return (
                                                <option value={status.statusId}>
                                                    {status.description}
                                                </option>
                                            )
                                        })
                                        :
                                        currentSelectedProjectInfo.currentStatus !== undefined ?
                                            <option selected>
                                                {currentSelectedProjectInfo.currentStatus}
                                            </option>
                                            : null}
                                </select>
                                {projectObj.workEffortName === undefined ?
                                    <input style={{ width: 650, marginLeft: 66 }} value="" onChange={updateProjectConfig} name="workEffortName" placeholder={uiLabelMap.FormFieldTitle_projectName + " *  "}></input>
                                    : <input style={{ width: 650, marginLeft: 66 }} value={projectObj.workEffortName} onChange={updateProjectConfig} name="workEffortName" placeholder={uiLabelMap.FormFieldTitle_projectName + " * "}></input>}
                                <br></br>
                                <input disabled={subProjCheckbox === false ? true : false} autoComplete="off" style={{ marginLeft: 66, width: 310 }} onChange={onFilterParentProject} type="text" id="workEffortParentId" name="workEffortParentId" placeholder={uiLabelMap.FormFieldTitle_parentProjectName} list="parent-project-list" />

                                <datalist id="parent-project-list">
                                    {projectList ?
                                        projectList.map((subProject) => {
                                            return (
                                                <option>
                                                    {subProject.workEffortName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>
                                <input autoComplete="off" style={{ marginLeft: 20, width: 310 }} onChange={onFilterFacility} type="text" id="facilityId" name="facilityId" placeholder={uiLabelMap.FacilityFacility + " * "} list="facility-list" />

                                <datalist id="facility-list">
                                    {facilityList ?
                                        facilityList.map((facility) => {
                                            return (
                                                <option>
                                                    {facility.facilityName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>

                                <input autoComplete="off" style={{ marginLeft: 66, width: 310, marginRight: '100%' }} onChange={onFilterProduct} type="text" id="productId" name="productId" placeholder={uiLabelMap.ProductProductName + " *"} list="product-list" />

                                <datalist id="product-list">
                                    {productList ?
                                        productList.map((product) => {
                                            return (
                                                <option>
                                                    {product.productName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>

                                <input autoComplete="off" style={{ marginLeft: 66, width: 310 }} onChange={onFilterTextChange} type="text" id="INTERNAL_ORGANIZATIO" name="INTERNAL_ORGANIZATIO" placeholder={uiLabelMap.OrderOrderEntryInternalOrganization + " * "} list="internal-company-list" />

                                <datalist id="internal-company-list">
                                    {interCompanyList.length !== 0 ?
                                        interCompanyList.map((party) => {
                                            return (
                                                <option>
                                                    {party.groupName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>
                                <input autoComplete="off" style={{ marginLeft: 20, width: 310 }} onChange={onFilterTextChange} type="text" id="PROVIDER_MANAGER" name="PROVIDER_MANAGER" placeholder={uiLabelMap.ProjectMgrProjectDirector + " * "} list="project-director-list" />
                                <datalist style={{ marginLeft: 66 }} id="project-director-list">
                                    {projDirectorList ?
                                        projDirectorList.map((party) => {
                                            return (
                                                <option>
                                                    {party.lastName + ", " + party.firstName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>

                                <input autoComplete="off" style={{ marginLeft: 66, width: 310 }} onChange={onFilterTextChange} type="text" id="CLIENT_BILLING" name="CLIENT_BILLING" placeholder={uiLabelMap.AccountingCustomer + " *"} list="client-company-list" />

                                <datalist id="client-company-list">
                                    {clientBillingPartyList.length !== 0 ?
                                        clientBillingPartyList.map((party) => {
                                            return (
                                                <option>
                                                    {party.groupName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>
                                <input autoComplete="off" style={{ marginLeft: 20, width: 310 }} onChange={onFilterTextChange} type="text" id="CLIENT_MANAGER" name="CLIENT_MANAGER" placeholder={uiLabelMap.ProjectMgrProjectDirectorClient + " *"} list="project-director-client-list" />

                                <datalist id="project-director-client-list">
                                    {projClientDirectorList ?
                                        projClientDirectorList.map((party) => {
                                            return (
                                                <option>
                                                    {party.lastName + ", " + party.firstName}
                                                </option>
                                            )
                                        })
                                        : null}
                                </datalist>


                                <br></br>

                                <div style={{ width: 700, marginLeft: 30 }}>
                                    <label style={{ marginLeft: 42 }}>{uiLabelMap.ProjectMgrProjectMember} / {uiLabelMap.ProjectMgrMemberRole}</label>

                                    <button style={{ width: 100, marginLeft: 360, cursor: 'pointer' }} onClick={addMemberModal}>+ {uiLabelMap.ProjectMgrProjectMember}</button>
                                    <div className="members">
                                        {projOtherAssignee !== null ?
                                            projOtherAssignee.map((party) => {
                                                return (
                                                    <div style={{ flexDirection: 'row' }}>
                                                        <div className="memberCard">{party.partyFirstName + " " + party.partyLastName} ({party.roleDesc + "  "})<div className="crossParty" onClick={() => removeAssignedParty(party)}>&#10006;</div></div>

                                                    </div>
                                                )
                                            })
                                            : "No Record"
                                        }
                                    </div>
                                </div>

                                <div style={{ width: 700, marginLeft: 30, marginTop: 30 }}>
                                    <label style={{ marginLeft: 42 }}>{uiLabelMap.ProjectMgrResultingProjectName}:</label>

                                    <input id="resultProj" style={{ width: 450, marginLeft: 10 }} value={resultedProjectName}></input>
                                </div>

                                <div style={{ marginRight: 70, marginLeft: 70, marginBottom: 10 }}>
                                    <button style={{ cursor: 'pointer' }} onClick={() => onCreateProject(true)}>{uiLabelMap.ProjectMgrCreateProject}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        showMemberModal === true ?
                            <AddMember onClose={addMemberModal} modalData={addMemberModalData} personParties={personParties} teamPartyList={teamPartyList} memberPartyRoles={projOtherAssignee} projectPartyRoles={projAssignPartyList} /> :
                            null
                    }

                    {actionState === true ?
                        <div style={{ backgroundColor: '#454545', width: 370, position: 'absolute', top: 200, left: 870 }}>
                            <div >
                                <i className="fas fa-pencil-alt"></i>
                                <h5 style={{ marginLeft: 10 }}>* {actionMessages.msg}</h5>
                                <h6 style={{ marginLeft: 10 }}>{actionMessages.projectName}</h6>
                                {failedPartyAssignments.length > 0 ?
                                    <>
                                        <hr />
                                        <h6 style={{ marginLeft: 10, color: 'red' }}>{uiLabelMap.ProjectMgrWarningLogLevel}:</h6>
                                        {failedPartyAssignments.map(err => {
                                            return <p style={{ marginLeft: 10 }}> * {err}</p>
                                        })}
                                    </>
                                    :
                                    null
                                }
                            </div>
                            <div>
                                <button style={{ cursor: 'pointer' }} onClick={cleanData}>{uiLabelMap.ProjectMgrOK}</button>
                            </div>

                        </div> : null}
                </> : <div class="loader"></div>
            }

        </>
    )

}

const mapStateToProps = state => {
    return {
        uiLabelList: state.labelsData.uiLabelList
    };
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);


