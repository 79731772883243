import axios from 'axios';
import { invalidateSession} from '../AppSession';
import { HEADERS } from '../AppHeaders';

export const login = (data) => {
    const encryptData = Buffer(data.username + ':' + data.password).toString('base64');
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/token`, {}, { headers: HEADERS.LOGIN(encryptData) });
}

export const logout = () => {
    invalidateSession();
}

export const createWorkEffort = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createWorkEffort`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createWorkEffortAssoc = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createWorkEffortAssoc`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const assignWorkEffortParyRole = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/assignPartyToWorkEffort`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getTemplateProjectPhaseList = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getTemplateProjectPhaseList`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getProjectPhaseList = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getProjectPhaseList`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createDataResourceAndContent = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createDataResourceAndContent`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createDirectoryStructure = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createDirectoryStructure`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createProjectDirectory = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createProjectDirectory`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createPhaseStructure = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createPhaseStructure`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getValidStatusTransitions = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getValidStatusTransitions`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createProjCustomSymbLink = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createProjCustomSymbLink`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const generateProjectName = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/generateProjectName`, data, {
        data: {}, headers: await HEADERS.SECURED()
    }).catch(err => {
        return err.response
    })
}

export const createWorkEffortIdentification = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createWorkEffortIdentification`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createWorkEffortGoodStandard = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createWorkEffortGoodStandard`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const performFindList = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/performFindList`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getWorkEffortInfo = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/getWorkEffort`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createSequenceValueItem = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/createProjectSequence`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const updateWorkeffort = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/services/updateWorkEffort`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}
